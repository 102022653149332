import React from 'react';
import { 
    Card,
    CardContent,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
    Button  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from "@material-ui/icons/Delete";
import '../../styles/ControlPanel.css';
/*
    Req
        MVP
            1. display list of current signees with their details in a list
            2. allow to delete the signees
        Feature-Complete
            3. edit current signee details
*/

const useStyles = makeStyles({
    root: {
      minWidth: "100%"
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    signeeNum: {
        fontSize: 15,
        textAlign: 'center',
        fontWeight: 'bold',
        paddingTop: '1rem',
    },
    signeeContainer: {
        padding: 'unset',
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'column',
        width: "80%",
        marginLeft: 'auto',
        marginRight: 'auto',
        overflowY: "scroll",
        maxHeight: "300px",
    },
    signeeCard: {
        borderTop: "1px solid rgba(0, 0, 0, 0.1);",
        position: "relative",
        display: "flex",
        textAlign: 'center',
        width: "100%",
        padding: "1rem 0rem",
    }
});

const SigneeControlPanel = ({context}) => {
    const {state, dispatch, deleteUser} = context;
    const {cellsInEdit, cellSlotIndex, slots} = state;
    const classes = useStyles();

    let JSX = (<></>);

    if(cellsInEdit !== null) {
        let slotsToEdit = slots.filter(slot => slot.ID === cellSlotIndex[cellsInEdit[0][0]][cellsInEdit[0][1]]);
        if(slotsToEdit.length > 0 && slotsToEdit[0].Signees.length > 0) {
            let signees = slotsToEdit[0].Signees;
            let signeeJSX = signees.map(signee => {
                return (
                    <div className={classes.signeeCard} variant="outlined">
                        <span style={{width: "30%"}}> {signee.FullName}</span>
                        <span style={{width: "30%"}}>{signee.Email}</span>
                        <span style={{width: "30%"}}>{signee.Telephone}</span>
                        {/* updateBooking(dispatch, slot, newSlotData, slotInEdit.ID) */}
                        <DeleteIcon onClick={() => deleteUser(dispatch, slotsToEdit[0], signee, slotsToEdit[0].ID)} style={{width: "10%"}}/>
                    </div>
                );
            })
            JSX = (
                <div className="signees-control-panel">
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <div className={classes.title}>
                                <span > Signee Control Panel </span>
                            </div>
                            <div className={classes.signeeNum}>
                                <span > Signees: {signees.length} </span>
                            </div>
                            <Card className={classes.signeeContainer}  variant="outlined">
                                {signeeJSX}
                            </Card>
                        </CardContent>
                    </Card>
                </div>
            );
        }
    }
    return JSX; 
}

export default SigneeControlPanel;