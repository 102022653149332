import React, {useContext, useState, useEffect} from 'react';
import Calendar from '../components/AdminBookingCalendar';
import ControlPanel from '../components/ControlPanel';
import BalletBookingSlotsState, { BalletBookingSlotsContext } from '../state/balletBookingSlotsState';
import ZumbaBookingSlotsState, { ZumbaBookingSlotsContext } from '../state/zumbaBookingSlotsState';
import {   
  getBalletBookingSlotsWithState,
  createBalletBookingSlotWithState,
  updateBalletBookingSlotWithState,
  deleteUserToBalletBookingSlotWithState,
  deleteBalletBookingSlotWithState 
} from '../api/BalletUtils';
import { 
  getZumbaBookingSlotsWithState,
  createZumbaBookingSlotWithState,
  updateZumbaBookingSlotWithState,
  deleteUserToZumbaBookingSlotWithState,
  deleteZumbaBookingSlotWithState  } from '../api/ZumbaUtils';

import '../styles/Admin.css';

export const Admin = () => {
  const { bbsDispatch, bbsState } = useContext(BalletBookingSlotsContext);
  const { zbsDispatch, zbsState } = useContext(ZumbaBookingSlotsContext);
  const [ context, setContext ] = useState({
          dispatch: bbsDispatch, 
          state: bbsState, 
          createBooking: createBalletBookingSlotWithState, 
          updateBooking: updateBalletBookingSlotWithState,
          deleteBooking: deleteBalletBookingSlotWithState,
          deleteUser: deleteUserToBalletBookingSlotWithState,
        });
  const [store, setStore] = useState("zumba");

  useEffect(() => {
    if(store === "ballet") {
      getBalletBookingSlotsWithState(bbsDispatch);
    }
    else if(store === "zumba") {
      getZumbaBookingSlotsWithState(zbsDispatch)
    }
      
  }, [bbsState.reload, zbsState.reload, store]);

  useEffect(() => {
    if(store === "ballet") {
      setContext({
        dispatch: bbsDispatch, 
        state: bbsState, 
        createBooking: createBalletBookingSlotWithState, 
        updateBooking: updateBalletBookingSlotWithState,
        deleteBooking: deleteBalletBookingSlotWithState,
        deleteUser: deleteUserToBalletBookingSlotWithState,
      });
    } else if(store === "zumba") {
      setContext({
        dispatch: zbsDispatch, 
        state: zbsState, 
        createBooking: createZumbaBookingSlotWithState, 
        updateBooking: updateZumbaBookingSlotWithState,
        deleteBooking: deleteZumbaBookingSlotWithState,
        deleteUser: deleteUserToZumbaBookingSlotWithState,
      });
    }
    
  }, [bbsState, zbsState, store]);


  return (
    <div className="admin-page-container">
      <div className="calendar-options">
        <div className={store === "ballet"? "option selected" : "option"} onClick={() => {setStore("ballet")}}>Ballet</div>
        <div className={store === "zumba"? "option selected" : "option"} onClick={() => {setStore("zumba")}}>Zumba</div>
      </div>
      <Calendar state={context.state} dispatch={context.dispatch} />
      <ControlPanel context={context} />
    </div>
  );
}

const AdminWithState = () => {
  return (
    <BalletBookingSlotsState>
      <ZumbaBookingSlotsState>
        <Admin/>
      </ZumbaBookingSlotsState>
    </BalletBookingSlotsState>
  );
}

export default AdminWithState;