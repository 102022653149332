import React from 'react';

const Row = (props) => {
    return (
        <tr className={'calendar-row'}>
            {props.children}
        </tr>
    );
}

export default Row;