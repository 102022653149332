import React from "react";
import "../styles/Home.css";
import Grid from '@material-ui/core/Grid';
import VaidaIconJPG from '../images/VaidaIcon.jpg';
import BookBalletJPG from '../images/BalletImage.jpg';
import BookZumbaJPG from '../images/ZumbaImage.jpg';
import BookKidsBalletJPG from '../images/KidsBalletImage.jpg'
import Map from '../components/GoogleMapsWidget';


const Home = () => {
    return (
        <Grid container spacing={0}>
            <Grid item lg={12} md={12} xs={12}>
                <div className="welcome-container">
                    <div className="hero-image"/>
                    <span className="welcome-title"> Welcome to Vaida’s Dance Studio </span>
                </div>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
                <div className="class-description">
                    <span>Vaida’s Dance Studio is a friendly, professional Dance Studio specialising in Kids Ballet. Additionally, the studio offers Zumba and Adult Ballet classes for any age and ability.</span>
                    <span>Whether you are attending for the joy of dance or have the desire to become professional dancers, we will help. Focussing on technique, performance whilst building confidence in students.</span>
                    <span>All our teaching staff are ex-professional dancers and qualified teachers, with up to date enhanced DBS certificates and insurance. They are also trained in child protection and safe teaching practices, and attend courses for CPD.</span>
                </div>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
                <div className="book-container right__aligned">
                    <a href="/ballet">
                        <img src={BookBalletJPG} className="book-image"/>
                    </a>
                </div>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
            <div className="book-container">
                    <a href="/zumba">
                        <img src={BookZumbaJPG} className="book-image"/> 
                    </a>
                </div>
            </Grid>
            <Grid item lg={4} md={12} xs={12}>
            <div className="book-container left__aligned">
                    <a href="/kids-ballet">
                        <img src={BookKidsBalletJPG} className="book-image"/> 
                    </a>
                </div>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
                <div className="parting-container">
                    <div className="about-me-container">
                        <div className="img-container">
                            <img src={VaidaIconJPG} className="about-me-icon"/>
                        </div>
                        <span>
                            Vaida Bartoseviciene
                        </span><span> 
                            Teacher and Owner of Vaida's Dance Studio
                        </span>
                        <span style={{marginBottom: "0.4rem"}}> Vaida Bartoseviciene started her studies in ballet from the age of 12 at Vilnius state ballet school and Kaunas state Musical theatre, in Lithuania. After a couple years of studying, she gained excellent skills and started to dance in various performances.</span>
                        <span style={{marginBottom: "0.4rem"}}>After she finished school, Vaida commenced her studies at Klaipeda University. She achieved a theatre bachelor qualification degree (British Bachelor of Arts Honours degree) and a professional qualification as a choreographer. At University, she studied different styles of dance: Ballet-classical dance, Modern, Character, Historical dances and more, but her passion from the beginning is and still lies with ballet.</span>
                        <span style={{marginBottom: "0.4rem"}}>After completing her studies, Vaida had an opportunity to teach at a prestigious school of art, teaching children of different ages. She worked on creating choreography and taught ballet, contemporary and folk dances.</span>
                        <span style={{marginBottom: "0.4rem"}}>In 2012 Vaida came to the UK and began working as a ballet teaching assistant in 2013. In 2015 she became a fully qualified Dance Teacher with the IDTA (International Dance Teachers Association) and a fully licensed Zumba instructor. Vaida is a member and an Associate of the Ballet Faculty.</span>
                    </div>
                    <div className="location-container">
                        {<Map/>}
                        <div className="location-title">
                            Location
                        </div>
                        <div className="location-address">
                            <span> Beechen Hall  </span><br/>
                            <span> 9 Wildfell Close, </span><br/>
                            <span> Walderslade, Chatham, </span><br/>
                            <span> ME5 9RU </span>
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}

export default Home;

