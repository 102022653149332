import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Card,
  CardContent,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Button  } from '@material-ui/core';

import { 
  NUM_OF_HOURS, 
  MAX_SLOTS } from '../../state/balletBookingSlotsState';

import SigneeControlPanel from "./SigneesControlPanel";
  
import '../../styles/ControlPanel.css';



const changeInEditCells = (numOfHours, numOfMinutes, cellsInEdit, dispatch) => {
  let newCellsInEdit = [];
  let i = 0;
  while(i < numOfHours * 4) {
    newCellsInEdit.push([cellsInEdit[0][0]+ i, cellsInEdit[0][1]]);
    i++;
  }
  for(let k = 0; k < numOfMinutes; k+=15) {
    newCellsInEdit.push([cellsInEdit[0][0]+ (i + k / 15), cellsInEdit[0][1]]);
  }

  dispatch({type:"SET_STATE_VALUES", payload: {cellsInEdit: newCellsInEdit}});

}

const useStyles = makeStyles({
  root: {
    minWidth: 200,

  },
  formControl: {
    minWidth: 120,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  startTime: {
    fontSize: 15,
    textAlign: 'center',
    fontWeight: 'bold',
    paddingTop: '1rem',
  },
  duration: {
    paddingTop: '1rem',
    textAlign: 'center',
  },
  spaces: {
    paddingTop: '1rem',
    textAlign: 'center',
  },
  create: {
    paddingTop: '1rem',
    textAlign: 'center'
  },
  updateDelete: {
    paddingTop: '1rem',
    textAlign: 'center'
  },
  update: {
    marginRight: '1rem',
  }
});

const ControlPanel = ({context}) => {
    const {state, dispatch, createBooking, updateBooking, deleteBooking} = context;
    const {cellsInEdit, cellSlotIndex, cellTimeIndex, timeCellIndex, slots} = state;

    let defaultHours = 0;
    let defaultMinutes = 15;
    let defaultSpaces = 10;

    const [minutes, setMinutes ] = useState(defaultMinutes);
    const [hours, setHours ] = useState(defaultHours);
    const [spaces, setSpaces ] = useState(defaultSpaces);
    const [slotInEdit, setSlotInEdit ] = useState(null);
    const classes = useStyles();


    useEffect(() => {
      if(cellsInEdit !== null) {
        let remainder = cellsInEdit.length % 4;
        let numOfHours = cellsInEdit.length / 4;;

        if(remainder) {
          setMinutes(remainder * 15);
        } else {
          setMinutes(0);
        }
        if(Math.floor(numOfHours) > 0) {
          setHours(Math.floor(numOfHours));
        } else {
          setHours(0);
        }
        if(cellSlotIndex[cellsInEdit[0][0]][cellsInEdit[0][1]] !== null) {
          let slotToEdit = slots.filter(slot => slot.ID === cellSlotIndex[cellsInEdit[0][0]][cellsInEdit[0][1]])[0];
            setSlotInEdit(slotToEdit);
            setSpaces(slotToEdit.MaxSlots);
          } else {
            setSlotInEdit(null);
            setSpaces(defaultSpaces);
          }
        } else {
          setHours(defaultHours);
          setMinutes(defaultMinutes);
      }
      

    }, [cellsInEdit])

    let JSX;

    if(cellsInEdit === null) {
      JSX = <></>
    } else {

        if(slotInEdit) {
         /*
            make two list one for available hours choice and one for minutes
            pick for in edit, devide by 4
            work out duratiion limit for combination of minutes and hours

         */


          let startCell = timeCellIndex[slotInEdit.StartTime];

          let i = startCell[0];
          let minuteLimit = 0;
  
          while(i < (NUM_OF_HOURS * 4) && (cellSlotIndex[i][startCell[1]] === null || cellSlotIndex[i][startCell[1]] === slotInEdit.ID )) {
            minuteLimit+=15;
            i++
          }

          let hoursLimit = Math.floor(minuteLimit / 60);
          let minutesLimit = hoursLimit - hours > 0? 45 : minuteLimit %  60;

          let hoursMenuItemList = [];
          let minutesMenuItemList = [];

  
          for(let i = 0; i <= hoursLimit; i++) {
            hoursMenuItemList.push(<MenuItem key={i} value={i}> {i} hr</MenuItem>);
          }
          for(let i = 0; i <= minutesLimit; i+=15) {
            if(!hours && i !== 0) {
              minutesMenuItemList.push(<MenuItem key={i} value={i}> {i} min</MenuItem>);
            } else if(hours) {
              minutesMenuItemList.push(<MenuItem key={i} value={i}> {i} min</MenuItem>);
            }

          }


          let spacesMenuItemList = []

          for(let i = 1; i <= MAX_SLOTS; i++) {
   
            spacesMenuItemList.push(<MenuItem key={i} value={i}> {i} spaces</MenuItem>);
          }
          
          let newSlotData = {};
          if(slotInEdit.MaxSlots !== spaces) {           
            newSlotData.MaxSlots = spaces;
          }
          if(slotInEdit.EndTime !== Moment(slotInEdit.StartTime, "HH:mm DD-MM-YYYY").add(hours, 'hours').add(minutes, 'Minutes').format("HH:mm DD-MM-YYYY")) {
            newSlotData.EndTime = Moment(slotInEdit.StartTime, "HH:mm DD-MM-YYYY").add(hours, 'hours').add(minutes, 'Minutes').format("YYYY-MM-DD HH:mm");
          }

          JSX = (
            <div className="control-panel">
                  <Card className={classes.root} variant="outlined">
                      <CardContent>
                        <div className={classes.title}>
                              <span > Control Panel </span>
                        </div>
                        <div className={classes.startTime}>
                          <span> Slot: {Moment(slotInEdit.StartTime, "HH:mm DD-MM-YYYY").format("HH:mm")} - {Moment(slotInEdit.StartTime, "HH:mm  DD-MM-YYYY").add(hours, 'hours').add(minutes, 'Minutes').format("HH:mm")} </span>
                        </div>
                        <div className={classes.duration}>
                          <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Hours</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={hours}
                              onChange={(event) => { 
                                changeInEditCells(event.target.value, hoursLimit - event.target.value === 0? 0 : minutes || (() => { setMinutes(15); return 15})(), cellsInEdit, dispatch);
                              }}
                            >
                              { hoursMenuItemList }
                            </Select>
                          </FormControl>
                        </div>
                        <div className={classes.duration}>
                          <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Minutes</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={minutes}
                              onChange={(event) => { 
                                changeInEditCells(hours, event.target.value, cellsInEdit, dispatch);
                              }}
                            >
                              { minutesMenuItemList }
                            </Select>
                          </FormControl>
                        </div>
                        <div className={classes.spaces}>
                          <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Spaces</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={spaces}
                              onChange={(event) => {
                                setSpaces(event.target.value)
                              }}
                            >
                              { spacesMenuItemList }
                            </Select>
                          </FormControl>
                        </div>
                        {/* <div className={classes.create}>
                          <Button variant="contained" color="primary">
                            Update
                          </Button>
                        </div> */}
                        <div className={classes.updateDelete}>
                          <Button className={classes.update} variant="contained" color="primary" onClick={() => {updateBooking(dispatch, slotInEdit, newSlotData, slotInEdit.ID)}}>
                            Update
                          </Button>
                          <Button  variant="contained" color="secondary" onClick={() => {deleteBooking(dispatch, slotInEdit.ID)}}>
                            Delete
                          </Button>
                        </div>
                      </CardContent>
                  </Card>
              </div>
          );
        
      } else {

        let i = cellsInEdit[0][0];
        let startCell = cellsInEdit[0][1];
        let minuteLimit = 0;

        while(i < (NUM_OF_HOURS * 4)  && (cellSlotIndex[i][startCell] === null )) {
          minuteLimit+=15;
          i++
        }
        let hoursLimit = Math.floor(minuteLimit / 60);
        let minutesLimit = hoursLimit > 0? 45 : minuteLimit %  60;

        let hoursMenuItemList = [];
        let minutesMenuItemList = [];


        for(let i = 0; i <= hoursLimit; i++) {
          hoursMenuItemList.push(<MenuItem key={i} value={i}> {i} hr</MenuItem>);
        }
        for(let i = 0; i <= minutesLimit; i+=15) {
          if(!hours && !i) {
            
          } else if(hoursLimit - hours > 0) {
            minutesMenuItemList.push(<MenuItem key={i} value={i}> {i} min</MenuItem>);
          } else if(!i) {
            minutesMenuItemList.push(<MenuItem key={0} value={0}> {0} min</MenuItem>);
          }

        }

        let spacesMenuItemList = []

        for(let i = 1; i <= MAX_SLOTS; i++) {
 
          spacesMenuItemList.push(<MenuItem key={i} value={i}> {i} spaces</MenuItem>);
        }

        JSX = (
          <div className="control-panel">
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                      <div className={classes.title}>
                            <span > Control Panel </span>
                      </div>
                      <div className={classes.startTime}>
                        <span> Slot: {Moment(cellTimeIndex[cellsInEdit[0][0]][cellsInEdit[0][1]], "HH:mm DD-MM-YYYY").format("HH:mm")} - {Moment(cellTimeIndex[cellsInEdit[0][0]][cellsInEdit[0][1]], "HH:mm  DD-MM-YYYY").add(hours, 'hours').add(minutes, 'Minutes').format("HH:mm")} </span>
                      </div>
                      <div className={classes.duration}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">Hours</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={hours}
                            onChange={(event) => { 
                              changeInEditCells(event.target.value, hoursLimit - event.target.value === 0? 0 : minutes || (() => { setMinutes(15); return 15})(), cellsInEdit, dispatch);
                            }}
                          >
                            { hoursMenuItemList }
                          </Select>
                        </FormControl>
                      </div>
                      <div className={classes.duration}>
                          <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Minutes</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={minutes}
                              onChange={(event) => { 
                                changeInEditCells(hours, event.target.value, cellsInEdit, dispatch);
                              }}
                            >
                              { minutesMenuItemList }
                            </Select>
                          </FormControl>
                        </div>
                      <div className={classes.spaces}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">Spaces</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={spaces}
                            onChange={(event) => {setSpaces(event.target.value)}}
                          >
                            { spacesMenuItemList }
                          </Select>
                        </FormControl>
                      </div>
                      <div className={classes.create}>
                        <Button 
                          variant="contained" 
                          color="primary" 
                          onClick={() => {
                            createBooking(dispatch, 
                                {
                                  StartTime: cellTimeIndex[cellsInEdit[0][0]][cellsInEdit[0][1]],
                                  EndTime: Moment(cellTimeIndex[cellsInEdit[0][0]][cellsInEdit[0][1]], "HH:mm DD-MM-YYYY").add(hours, 'hours').add(minutes, 'Minutes').format("HH:mm DD-MM-YYYY"),
                                  MaxSlots: spaces
                              });
                          }}>
                          Create
                        </Button>
                      </div>
                    </CardContent>
                </Card>
            </div>
        );
      }
    }

    return JSX;

}

export default ({context}) => (
  <> 
    <ControlPanel context={context} />
    <SigneeControlPanel context={context} />  
  </>
);