import React from 'react';
import LoaderSVG from '../../images/Loader.svg';
import '../../styles/Loader.css';

export default () => {
    return (
        <div className="loader-background">
            <img src={LoaderSVG}/>
        </div>
    );

}