import React, { useState, useContext } from 'react';
import Moment from 'moment';

import '../../styles/BookingDayEntry.css';
import ExitIconSVG from '../../images/ExitIcon.svg'

const BookingDayEntry = ({ current, signUpUser, setSlots, slots, dispatch}) => {

    let [ slotToBook, setSlotToBook ] = useState(null);
    let [ fullName, setFullName ] = useState("");
    let [ email, setEmail ] = useState("");
    let [ telephone, setTelephone ] = useState("");
    let [ validationErrors, setValidationErrors] = useState([]);
    let calendarName = current;
    let date = Moment(slots[0].StartTime, "HH:mm DD-MM-YYYY").format('dddd MMMM Do');;
    let bookingOptions = slots.map(slot => {
        let className = slotToBook !== null && slot.ID === slotToBook.ID? "booking-day-option active" : "booking-day-option";

        return (
            <button type="radio" required onClick={(e) => { e.preventDefault(); setSlotToBook(slot)}} className={className} >{Moment(slot.StartTime, "HH:mm DD-MM-YYYY").format('h:mma')} - {Moment(slot.EndTime, "HH:mm DD-MM-YYYY").format('h:mma')}</button>
        );
    });
    return (
        <div className="booking-day-container">
            <div className="booking-day-card">
                <img src={ExitIconSVG} onClick={() => {setSlots([])}}/>
                <div className="vertical-container">
                    <div className="booking-day-header"> 
                        <span className="header-title" > 
                            {calendarName} 
                        </span> 
                        <span className="header-date" > 
                            {date}
                        </span> 
                    </div>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        if(slotToBook) {
                            signUpUser(
                                dispatch,
                                {
                                    FullName: fullName,
                                    Email: email,
                                    Telephone: telephone 
                                }, 
                                slotToBook.ID
                            );
                            setSlots([]);
                        } else {
                            setValidationErrors([<span className="booking-day-compulsory-text validation-error"> Please select a class </span>])
                        }
                    }}>
                        <div className="booking-day-info"> 
                            <span className="booking-day-compulsory-text"> 
                                Fields marked with * are compulsory
                            </span>
                            <span className="option-text"> 
                                Press on the class you would like to book: <span className="booking-day-compulsory-text">*</span>
                            </span>
                        </div>
                        <div className="booking-day-options">
                            {bookingOptions}
                        </div>
                        <div className="booking-day-form">
                            <span> 
                                Full name: <span className="booking-day-compulsory-text">*</span>
                            </span>
                            <input required type="text" onChange={(e) => {setFullName(e.target.value)}} placeholder="Your name here..."/>
                            <span > 
                                Email: <span className="booking-day-compulsory-text">*</span>
                            </span>
                            <input required type="email" name="email" onChange={(e) => {setEmail(e.target.value)}} placeholder="Your email here..."/>
                            <span > 
                                Telephone no:
                            </span>
                            <input type="tel" pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$" onChange={(e) => {setTelephone(e.target.value)}} placeholder="Your telephone no here..."/>
                            <span className="inform-text">
                                This is so we can contact you if there are any cancellations or changes to the class.
                            </span>
                            <span style={{paddingTop: "0.4rem"}} className="inform-text">
                                By booking the class you confirm that you are over 16 years of age and agree to our <a style={{ color: "#707070"}} href="/terms-and-conditions">terms & conditions</a> and  <a style={{ color: "#707070"}}href="/privacy-policy">privacy policy</a>.
                            </span>
                            <span className="inform-text">
                                Your booking details will be sent to your email address.
                            </span>
                        </div>
                        {validationErrors}
                        <div className="booking-day-form-submit">
                            <button type="submit"  onSubmit={() => {}}> 
                                Book 
                            </button>
                            
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default BookingDayEntry;