import React from 'react';
import FacebookLogo from '../../images/FacebookLogo.png';
import InstagramLogo from '../../images/InstagramLogo.png';
import Grid from '@material-ui/core/Grid';
import {
    Link
  } from "react-router-dom";
import "../../styles/Footer.css";

const Footer = () => {

    return (
        <div className="footer-container" >
                <Grid container spacing={0}>
                    <Grid item lg={6} md={6} xs={12}>
                        <div className="contact-container">
                            <span>Contact here for enquiries:</span><br/>
                            <span>Telephone No: +447880221287</span><br/>
                            <a href="mailto:vaidasdancestudio@gmail.com"><span>Email: vaidasdancestudio@gmail.com</span></a><br/>
                        </div>
                    </Grid>
                    <Grid  item lg={6} md={6} xs={12}>
                        <div className="legal-container">
                            <Link to="/policies-and-procedures">Policies & Procedures</Link><br/>
                            <Link to="/terms-and-conditions">Terms & Conditions</Link><br/>
                            <Link to="/privacy-policy">Privacy Policy</Link><br/>
                        </div>
                    </Grid>
                </Grid>
                <div className="social-container">
                    {/* svgs for facebnook and gram */}
                    <a href="https://www.facebook.com/Vaidass-Dance-Studio-125224162619010" className="facebook-logo" > <img src={FacebookLogo} /> </a>
                    <a href="https://www.instagram.com/vaidas.dance.studio/" className="instagram-logo"> <img src={InstagramLogo}  /> </a>
                </div>
        </div>
    );
}

export default Footer;