import postLogin from './Login';

export const postLoginWithState = async (dispatch, username, password) => {   
    dispatch({
        type: 'LOGIN_LOADING'
    });

    const resData = await postLogin(username, password);
    
    if(resData.status !== 200) {
        dispatch({ type: 'LOGIN_ERROR', payload: resData.statusText });
    } else {
        dispatch({ type: 'LOGIN_COMPLETED', payload: {}});
    }
}

export const formDownload = async () => {   
    let response = await fetch('./registration-form',    {
        method: 'GET',
    });

    let blob = await response.blob();
    
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'vds-registration-form.docx'); //or any other extension
    document.body.appendChild(link);
    link.click();
}