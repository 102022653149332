import React, { useContext, useState, useEffect } from 'react';
import Moment from 'moment';
import Cell from "./cell";
import Row from "./row";
import Loader from '../Loader';
import { BalletBookingSlotsContext, TIME_OFFSET, NUM_OF_DAYS, NUM_OF_HOURS } from '../../state/balletBookingSlotsState';
import '../../styles/Calendar.css';

/*
    responsibilities:
    - holds state of registered cells
    - creates session slots
    - manages time, 4 weeks from current 
    - grays out time that has passed on today
    
    24 x 24 - rows and columns, 24 hours for 24 days

    NEEDS:
        confirm button that is linked

*/

const buildCellStates = (slots, dispatch, timeCellIndex) => {
    let endCells = {};

    for (const [key, value] of Object.entries(timeCellIndex)) {
        let cellStartTime = Moment(key, "hh:mm DD-MM-YYYY");
        let cellEndTime = Moment(key, "hh:mm DD-MM-YYYY").add(15, 'm');

        slots.forEach(slot => {
            let slotStartTime = Moment(slot.StartTime, "hh:mm DD-MM-YYYY");
            let slotEndTime = Moment(slot.EndTime, "hh:mm DD-MM-YYYY");

            if(cellEndTime.isSame(slotEndTime)) {

                if(typeof endCells[value[0]] === "undefined") {
                    endCells[value[0]] = {};
                }
                endCells[value[0]][value[1]] = {
                    maxSlots: slot.MaxSlots, 
                    slotsLeft: slot.SlotsLeft
                };
            }

        });

    }

    dispatch({ type: 'SET_STATE_VALUES', payload: {endCells}});
        
}
const Header = () => {
    let headings = [];

    for (let i=0; i < NUM_OF_DAYS; i++) {
        headings[i] = (
            <th key={"heading="+ i}>
                <span >{Moment().add(i, 'days').format("ddd")} - {Moment().add(i, 'days').format("DD")}/{Moment().add(i, 'days').format("MM")}</span>
            </th>
        )
    }

    return (
        <thead>
            <tr>
                {headings}
            </tr>
        </thead>
    );
}

const Rows = (endCells, state, dispatch) => {
    let rows = [];

    for(let k=0; k < 4; k++) {


        for (let i=0; i <= NUM_OF_HOURS -1; i++) {

            let cells = [];

           
            for (let j=0; j < NUM_OF_DAYS; j++) {
                let cellContent;
                if(k == 3 ) {
                    cellContent = <span> {Moment([TIME_OFFSET+i, k*15],'HH:mm').format('HH:mm')} - {Moment([TIME_OFFSET+i+1, 0],'HH:mm').format('HH:mm')} </span>;
                } else {
                    cellContent = <span> {Moment([TIME_OFFSET+i, k*15],'HH:mm').format('HH:mm')} - {Moment([TIME_OFFSET+i, (k+1)*15],'HH:mm').format('HH:mm')} </span>;
                }
                if(endCells !== null && (i*4+k) in endCells) {
                    if(j in endCells[i*4+k]) {

                        cellContent = (
                            <>
                                { k == 3? 
                                    <> <span> {Moment([TIME_OFFSET+i, k*15],'HH:mm').format('HH:mm')} - {Moment([TIME_OFFSET+i+1, 0],'HH:mm').format('HH:mm')} </span> <br/> </>:
                                    <> <span> {Moment([TIME_OFFSET+i, k*15],'HH:mm').format('HH:mm')} - {Moment([TIME_OFFSET+i, (k+1)*15],'HH:mm').format('HH:mm')} </span> <br/> </>
                                }
                                <span> Max Slots: {endCells[i*4+k][j].maxSlots} </span> <br/>
                                <span> Slots Left: {endCells[i*4+k][j].slotsLeft} </span>
                            </>
                        );
                    }
                }

                cells[j] = (
                    <Cell  state={state} dispatch={dispatch} cellId={[i*4+k,j]}>
                        {cellContent}
                    </Cell>
                );
            }

            rows[i*4+k] = (
                <Row >
                    {cells}
                </Row>
            );
        }
    }
    
    return (
        <tbody>
            {rows}
        </tbody>
    );
}

const Calendar = ({state, dispatch}) => {

    const { slots, endCells, timeCellIndex, loading } = state;


    useEffect(() => {
        if(slots !== null) {
            buildCellStates(slots, dispatch, timeCellIndex);
        }
    }, [slots]);

    let JSX = (<></>);

    if(slots && !loading) {
        JSX = (
                <table className='calendar'>
                        {Header()}
                        {Rows(endCells, state, dispatch)}
                </table>
            );
    }
    if(loading) {
        JSX = (
                <div className='calendar'>
                    {<Loader/>}
                </div>
            );
    }
    return (
        JSX
    );

};


export default Calendar;