import {
    getZumbaBookingSlots,
    createZumbaBookingSlot,
    updateZumbaBookingSlot,
    signUpUserToZumbaBookingSlot,
    deleteZumbaBookingSlot
} from "./ZumbaBooking";

export const getZumbaBookingSlotsWithState = async (dispatch) => {
    dispatch({
        type: 'QUERY_LOADING'
    });

    const resData = await getZumbaBookingSlots();

    if(resData.status !== 200) {
        dispatch({ type: 'QUERY_ERROR',  payload: resData.statusText });

    } else  {
        let resDataJSON = await resData.json();
        dispatch({ type: 'QUERY_COMPLETED', payload: resDataJSON});  
    }
}

export const createZumbaBookingSlotWithState = async (dispatch, slot) => {
    dispatch({
        type: 'QUERY_LOADING'
    });

    const resData = await createZumbaBookingSlot(slot);
    
    if(resData.status !== 200) {
        dispatch({ type: 'QUERY_ERROR', payload: resData.statusText });
    } else {
        dispatch({ type: 'SET_STATE_VALUES', payload: {cellsInEdit: null, reload: true}});
        dispatch({ type: 'QUERY_COMPLETED', payload: []});
    }
}
export const updateZumbaBookingSlotWithState = async (dispatch, slot, newData, slotId ) => {
    dispatch({
        type: 'QUERY_LOADING'
    });

    let current = {...slot};
    let changes = {};

    if('MaxSlots' in newData) {
        current.MaxSlot = newData['MaxSlots'];
        changes.MaxSlot = newData['MaxSlots'];
    }
    if('EndTime' in newData) {
        current.EndTime = newData['EndTime'];
        changes.EndTime = newData['EndTime'];
    }

    let queryParams = {
        current: current,
        previous: slot,
        changes: changes
    };

    const resData = await updateZumbaBookingSlot(queryParams, slotId);

    if(resData.status !== 200) {
        dispatch({ type: 'QUERY_ERROR', payload: resData.statusText });
    } else {
        dispatch({ type: 'SET_STATE_VALUES', payload: {cellsInEdit: null, reload: true}});
        dispatch({ type: 'QUERY_COMPLETED', payload: []});
    }
}

export const signUpUserToZumbaBookingSlotWithState = async (dispatch, newData, slotId) => {
    dispatch({
        type: 'QUERY_LOADING'
    });
    
    let queryParams = {
        FullName: newData.FullName,
        Email: newData.Email,
        Add: newData.Add,  
        Telephone: newData.Telephone,
        ID: slotId
    };

    const resData = await signUpUserToZumbaBookingSlot(queryParams);

    if(resData.status !== 200) {
        dispatch({ type: 'QUERY_ERROR', payload: resData.statusText });
    } else {
        dispatch({ type: 'SET_STATE_VALUES', payload: {cellsInEdit: null, reload: true}});
        dispatch({ type: 'QUERY_COMPLETED', payload: []});
    }
}

export const deleteUserToZumbaBookingSlotWithState = async (dispatch, slot, userData, slotId ) => {
    dispatch({
        type: 'QUERY_LOADING'
    });
    let newSignees = slot.Signees.filter(signee => {
        return !(signee.Email === userData.Email && signee.FullName === userData.FullName && signee.Telephone === userData.Telephone);
    }); 

    let queryParams = {
        current: {...slot, Signees: newSignees},
        previous: slot,
        changes: {Signees: [userData]}
    };

    const resData = await updateZumbaBookingSlot(queryParams, slotId);

    if(resData.status !== 200) {
        dispatch({ type: 'QUERY_ERROR', payload: resData.statusText });
    } else {
        dispatch({ type: 'SET_STATE_VALUES', payload: {cellsInEdit: null, reload: true}});
        dispatch({ type: 'QUERY_COMPLETED', payload: []});
    }
}

export const deleteZumbaBookingSlotWithState = async (dispatch, slotID) => {
    dispatch({
        type: 'QUERY_LOADING'
    });

    const resData = await deleteZumbaBookingSlot(slotID);

    if(resData.status !== 200) {
        dispatch({ type: 'QUERY_ERROR', payload: resData.statusText });
    } else {
        dispatch({ type: 'SET_STATE_VALUES', payload: {cellsInEdit: null, reload: true}});
        dispatch({ type: 'QUERY_COMPLETED', payload: []});
    }
}

export default {
    getZumbaBookingSlotsWithState,
    createZumbaBookingSlotWithState,
    updateZumbaBookingSlotWithState,
    signUpUserToZumbaBookingSlotWithState,
    deleteUserToZumbaBookingSlotWithState,
    deleteZumbaBookingSlotWithState,
};