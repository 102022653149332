import Moment from 'moment';

export const getZumbaBookingSlots = async () => {
    const res = await fetch('/api/zumba/slots',
        {
            method: 'GET', 
            headers: { 
                'Content-Type': 'application/json'
            },
        }
    );

    return res;

}

export const createZumbaBookingSlot = async ({StartTime, EndTime, MaxSlots}) => {

    let reformattedStartTime = Moment(StartTime, "HH:mm DD-MM-YYYY").format("YYYY-MM-DD HH:mm");
    let reformattedEndTime = Moment(EndTime, "HH:mm DD-MM-YYYY").format("YYYY-MM-DD HH:mm");

    const res = await fetch(`/api/zumba/slots?StartTime=${reformattedStartTime}&EndTime=${reformattedEndTime}&MaxSlots=${MaxSlots}`,
        {
            method: 'POST', 
            headers: { 
                'Content-Type': 'application/json'
            },
        }
    );

    return res;
}


export const  signUpUserToZumbaBookingSlot = async ({FullName, Email, Telephone, Add, ID}) => {

    const res = await fetch(`/api/zumba/slots/${ID}/user?FullName=${FullName}&Email=${Email}&Add=${Add}&Telephone=${Telephone === ""? undefined : Telephone}`,
        {
            method: 'PUT', 
            headers: { 
                'Content-Type': 'application/json'
            },
        }
    );

    return res;
}

export const  updateZumbaBookingSlot = async ({previous, current, changes}, ID) => {
    const res = await fetch(`/api/zumba/slots/${ID}?previous=${JSON.stringify(previous)}&current=${JSON.stringify(current)}&changes=${JSON.stringify(changes)}`,
        {
            method: 'PUT', 
            headers: { 
                'Content-Type': 'application/json'
            },
        }
    );

    return res;
}

export const deleteZumbaBookingSlot = async (ID) => {
    const res = await fetch(`/api/zumba/slots/${ID}`,
        {
            method: 'DELETE', 
            headers: { 
                'Content-Type': 'application/json'
            },
        }
    );

    return res;
}

export default {
    getZumbaBookingSlots,
    createZumbaBookingSlot,
    updateZumbaBookingSlot,
    deleteZumbaBookingSlot,
    signUpUserToZumbaBookingSlot
};
