import Moment from 'moment';

export const getBalletBookingSlots = async () => {
    const res = await fetch('/api/ballet/slots',
        {
            method: 'GET', 
            headers: { 
                'Content-Type': 'application/json'
            },
        }
    );

    return res;

}

export const createBalletBookingSlot = async ({StartTime, EndTime, MaxSlots}) => {

    let reformattedStartTime = Moment(StartTime, "HH:mm DD-MM-YYYY").format("YYYY-MM-DD HH:mm");
    let reformattedEndTime = Moment(EndTime, "HH:mm DD-MM-YYYY").format("YYYY-MM-DD HH:mm");

    const res = await fetch(`/api/ballet/slots?StartTime=${reformattedStartTime}&EndTime=${reformattedEndTime}&MaxSlots=${MaxSlots}`,
        {
            method: 'POST', 
            headers: { 
                'Content-Type': 'application/json'
            },
        }
    );

    return res;
}

export const  signUpUserToBalletBookingSlot = async ({FullName, Email, Telephone, Add, ID}) => {

    const res = await fetch(`/api/ballet/slots/${ID}/user?FullName=${FullName}&Email=${Email}&Add=${Add}&Telephone=${Telephone === ""? undefined : Telephone}`,
        {
            method: 'PUT', 
            headers: { 
                'Content-Type': 'application/json'
            },
        }
    );

    return res;
}

export const  updateBalletBookingSlot = async ({previous, current, changes}, ID) => {
    const res = await fetch(`/api/ballet/slots/${ID}?previous=${JSON.stringify(previous)}&current=${JSON.stringify(current)}&changes=${JSON.stringify(changes)}`,
        {
            method: 'PUT', 
            headers: { 
                'Content-Type': 'application/json'
            },
        }
    );

    return res;
}

export const deleteBalletBookingSlot = async (ID) => {
    const res = await fetch(`/api/ballet/slots/${ID}`,
        {
            method: 'DELETE', 
            headers: { 
                'Content-Type': 'application/json'
            },
        }
    );

    return res;
}


export default {
    getBalletBookingSlots,
    createBalletBookingSlot,
    updateBalletBookingSlot,
    signUpUserToBalletBookingSlot,
    deleteBalletBookingSlot
};
