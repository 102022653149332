import React from "react";
import "../styles/Ballet.css";

const BookingCancelled = () => {
    return (
        <div style={{textAlign: 'center'}} className="booking-text">
            <span> Your booking has been cancelled! </span>
        </div>
    );
}

export default BookingCancelled;
