import React, { useState, useContext } from 'react';
import { postLoginWithState } from '../api/Utils';
import {UserContext} from '../state/userState';

const Login = () => {
    const {uDispatch} = useContext(UserContext);
    const [userName, setUsername] = useState("");
    const [password, setPassword] = useState("");

    return (
        <form 
            className="login-form" 
            style={{
                paddingTop: "14rem",
                textAlign: "center"
            }}>
            <span>username</span><input type="text" onChange={e => {setUsername(e.target.value)}}></input><br/>
            <span>password</span><input type="text" onChange={e => {setPassword(e.target.value)}}></input><br/>
            <input type="submit" onClick={(e) => {e.preventDefault(); postLoginWithState(uDispatch, userName, password)}}value="Log in!"></input>
        </form>
    )
}
export default Login;