import React, {useContext} from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import './styles/index.css';
import Admin from './pages/Admin';
import Home from './pages/Home';
import Zumba from './pages/Zumba';
import Ballet from './pages/Ballet';
import KidsBallet from './pages/KidsBallet';
import Login from './pages/Login';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PoliciesAndProcedures from './pages/PoliciesAndProcedures';
import BookingCancelled from './pages/BookingCancelled';
import Header from './components/Header';
import Footer from './components/Footer';
import UserState, { UserContext } from './state/userState';

/*
  redirect user to login screen  if session info is unset
*/

const App = () => {
  const {uState} = useContext(UserContext);
  const {isAuthenticated} = uState;

  return (
    <Router>
      <div className="content-container">
        <Header/>
        <div className="content-body">
          <Switch>
              <Route exact path="/">
                  <Home />
              </Route>
              <Route path="/admin">
                  {
                    isAuthenticated?
                      <Admin /> 
                      :
                      <Redirect to='/login'/>
                  }
              </Route>
              <Route path="/zumba">
                  <Zumba />
              </Route>
              <Route path="/ballet">
                  <Ballet />
              </Route>
              <Route path="/kids-ballet">
                  <KidsBallet />
              </Route>
              <Route path="/privacy-policy">
                  <PrivacyPolicy />
              </Route>
              <Route path="/terms-and-conditions">
                  <TermsAndConditions />
              </Route>
              <Route path="/policies-and-procedures">
                  <PoliciesAndProcedures />
              </Route>
              <Route path="/booking-cancelled">
                  <BookingCancelled />
              </Route>
              <Route path="/login">
                {
                  !isAuthenticated?
                    <Login /> 
                     :
                     <Redirect to='/admin'/>
                }
              </Route>
          </Switch>
        </div>
        <Footer/>
      </div>
    </Router>

  );
}




ReactDOM.render(
  <React.StrictMode>
    <UserState>
      <App/>
    </UserState>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
