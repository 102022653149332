import React, {useState, useContext} from 'react';
import Moment from 'moment';
import { BalletBookingSlotsContext } from '../../state/balletBookingSlotsState';
import "../../styles/Cell.css";

/*
    needs to know:
    - its state; active inactive
    - whether its disabled or enabled, use moment to decide whether to gray out
*/


const toggleCellState = (cellId, cellsInEdit, cellSlotIndex,  timeCellIndex, slots, dispatch) => {
    if(cellsInEdit === null || (cellId[0] !== cellsInEdit[0][0] || cellId[1] !== cellsInEdit[0][1])) {
        if(cellSlotIndex[cellId[0]][cellId[1]] !== null) {
            let cellSlot = slots.filter(slot => {
                // let startTimeLoc = timeCellIndex[cell.StartTime];
                return slot.ID === cellSlotIndex[cellId[0]][cellId[1]]
            })[0];

            let startTimeLoc = timeCellIndex[cellSlot.StartTime];
            let endTimeLoc = timeCellIndex[cellSlot.EndTime];

            let duration = endTimeLoc[0] - startTimeLoc[0];
            let newCellsInEdit = [];
            let i = 0;

            while(i < duration) {
                newCellsInEdit.push([startTimeLoc[0] + i, startTimeLoc[1]])
                i++;
            }

            if(cellsInEdit !== null && (newCellsInEdit.length === cellsInEdit.length && cellSlot.ID === cellSlotIndex[cellsInEdit[0][0]][cellsInEdit[0][1]])) {
                dispatch({type:"SET_STATE_VALUES", payload: {cellsInEdit: null}})
            } else {
                dispatch({type:"SET_STATE_VALUES", payload: {cellsInEdit: newCellsInEdit}})
            }
           


        } else {
            dispatch({type:"SET_STATE_VALUES", payload: {cellsInEdit: [cellId]}})
        }
            
    } else {
        dispatch({type:"SET_STATE_VALUES", payload: {cellsInEdit: null}})
    }
}

const Cell = ({ cellId, state, dispatch, children }) => {
    let  { cellsInEdit, cellSlotIndex, cellTimeIndex, timeCellIndex, slots } = state;

    let baseClassName = "calendar-cell";
    let className = baseClassName;

    if (cellSlotIndex[cellId[0]][cellId[1]] !== null) {

        if(cellsInEdit !== null) {
            let cellSlot = slots.filter(slot => {
                return slot.ID === cellSlotIndex[cellId[0]][cellId[1]];
            })[0];
            let startTimeLoc = timeCellIndex[cellSlot.StartTime];
            let endTimeLoc = timeCellIndex[cellSlot.EndTime];

            let duration = endTimeLoc[0] - startTimeLoc[0];
            let slotCells = [];
            let i = 0;
    
            while(i < duration) {
                slotCells.push([startTimeLoc[0] + i, startTimeLoc[1]])
                i++;
            }
    
            let slotCellsInEdit = cellsInEdit.filter(cell => {
                return slotCells.filter(slotCell => cell[0] === slotCell[0] && cell[1] === slotCell[1]).length > 0;
            });
    
            if(slotCellsInEdit.length === 0) {
                className = baseClassName+ " active";
            }
        } else {
            className = baseClassName+ " active";
        }
        

    }

    if(cellsInEdit !== null) {
        cellsInEdit.forEach(cellLoc => {
            if(cellLoc[0] === cellId[0] && cellLoc[1] === cellId[1]) {
                className = baseClassName+ " deactivate";
            }
        })
    }
    if(Moment().diff(Moment(cellTimeIndex[cellId[0]][cellId[1]], "HH:mm DD-MM-YYYY"), 'minutes') > 0) {
        className = baseClassName+ " disabled";
        
    }
    return (
        <td>
            {!className.includes("disabled")?
                <div className={className} onClick={() => {toggleCellState(cellId, cellsInEdit, cellSlotIndex, timeCellIndex, slots, dispatch );}}>
                    {children}      
                </div>
            :
                <div className={className} >
                    {children}      
                </div>
            }
        </td>
    );
}


export default Cell;