import React from 'react'
import { 
  Map, 
  InfoWindow, 
  Marker, 
  GoogleApiWrapper
} from 'google-maps-react';

const MapContainer = (props) => {
    return (
        <Map 
          google={props.google} 
          zoom={15} 
          className="location-map"
          initialCenter={{
            lat: "51.326726",
            lng: " 0.540402"
          }}
        >

          <Marker onClick={() => {}}
                  name={'Current location'} />

          <InfoWindow onClose={() => {}}>
              <div>
                <h1>awda</h1>
              </div>
          </InfoWindow>
        </Map>
    );
}

export default GoogleApiWrapper({
  apiKey: ("AIzaSyDROK-CuJ0Oo33Aluwchss0SgKRqxiHsIw")
})(MapContainer)


