import React from 'react';
import Grid from '@material-ui/core/Grid';

export default () => {
    return (
        <Grid container spacing={0}>
          <Grid  item lg={2} md={2} xs={1}>
          </Grid>
          <Grid   item lg={8} md={8} xs={10}>
            <h1 style={{width:'100%', textAlign: 'center'}}>Terms & Conditions</h1>
            <span style={{ display: "block", marginBottom: "0.1rem"}}>•	The Registration form is to be completed by the parent/guardian upon acceptance of a place and/or commencement of the first lesson.
            <strong>*This information is confidential and will not be shared with third parties.</strong></span><br/>
            <span style={{ display: "block", marginBottom: "0.1rem"}}>•	Fees are payable termly on or before the last day of the current term. Invoices for timetabled classes are distributed by email at the end of each term for the following term. Payments can be made by card in class, cash, or bank transfer. If a class is cancelled, it will rescheduled. If it is not possible to reschedule, fees will be subtracted from the forthcoming terms fees.</span><br/>
            <span style={{ display: "block", marginBottom: "0.1rem"}}>• Failure to pay may result in place being cancelled.</span><br/>
            <span style={{ display: "block", marginBottom: "0.1rem"}}>• Correct uniform needs to be worn in every class. No jewellery is to be worn in class.</span><br/>
            <span style={{ display: "block", marginBottom: "0.1rem"}}>• There are no refunds if a pupil is absent from a class and no reductions for part term attendance. If we have to cancel classes for any reason, we will post either on Facebook/Instagram, via email and where necessary by text. We will give you as much notice as possible</span><br/>
            <span style={{ display: "block", marginBottom: "0.1rem"}}>• If it is necessary to cancel a class due to adverse weather conditions, or circumstances beyond our control, class fees will not be refunded.</span><br/>
            <span style={{ display: "block", marginBottom: "0.1rem"}}>• If a child wishes to stop classes then 2 weeks’ notice must be given. If no notice is given then a bill for a half terms fees will be due, if notice is given after fees have been paid, then the termination date will be at the end of term.</span><br/>
            <span style={{ display: "block", marginBottom: "0.1rem"}}>• Parents will refrain from entering the studio during a class unless it is an emergency.</span><br/>
          </Grid>
          <Grid  item lg={2} md={2} xs={1}>
          </Grid>

        </Grid>
    );
}