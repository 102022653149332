import React, {useContext, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Calendar from '../components/UserBookingCalendar';
import BalletBookingSlotsState, { BalletBookingSlotsContext } from '../state/balletBookingSlotsState';
import { getBalletBookingSlotsWithState, signUpUserToBalletBookingSlotWithState } from '../api/BalletUtils';
import "../styles/Home.css";
import '../styles/Ballet.css';

export const Ballet = () => {
  const { bbsDispatch, bbsState } = useContext(BalletBookingSlotsContext);

  useEffect(() => {
    getBalletBookingSlotsWithState(bbsDispatch);
  }, [bbsState.reload]);

  return (
    <Grid container spacing={0}>
        <Grid item lg={12} md={12} xs={12}>
          <div className="hero-image-ballet"/>
          <span className="ballet-title"> Adult Ballet </span>
        </Grid>
        <Grid item lg={4} md={2} xs={1}>
        </Grid>
        <Grid item lg={4} md={8} xs={10}>
            <div className="booking-text">
              <span>Book your Adult Ballet class here!</span>
              <span>Join one of our Adult Ballet Classes designed for all abilities. Adult ballet is a wonderful way to improve muscle strength. The legs, back and core are the main muscle groups which will benefit from strengthening though ballet exercises, but we also strengthen our arm muscles as well as neck and foot muscles!</span><br/>
              <ul style={{marginRight: 'auto', marginLeft: 'auto'}}>
                    <li style={{paddingRight:"0rem"}}>The following list outlines what to bring in case you are wondering</li><br/>
                    <li style={{paddingRight:"0rem"}}>T-shirt or tank top</li><br/>
                    <li style={{paddingRight:"0rem"}}>Leggings or fitting yoga pants</li><br/>
                    <li style={{paddingRight:"0rem"}}>Ballet shoes</li><br/>
                    <li style={{paddingRight:"0rem"}}>Take a bottle of water as you’ll need to stay hydrated.</li><br/>
              </ul><br/>
              <strong>
                <span>Price</span>
              </strong>
              <span> Each class is £5, paid at the class via cash or card.</span><br/>
              <span> Click to book your classes! </span>
            </div>
            <Calendar current={"Ballet"} signUpUser={signUpUserToBalletBookingSlotWithState} dispatch={bbsDispatch} state={bbsState} />
        </Grid>
        <Grid  item lg={4} md={2} xs={1}>
        </Grid>
    </Grid>
  );
}

const BalletWithState = () => {
  return (
    <BalletBookingSlotsState>
      <Ballet />
    </BalletBookingSlotsState>
  );
}

export default BalletWithState;