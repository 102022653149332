import CryptoJS from 'crypto-js';

const postLogin = async (username, password) => {
    let URLEncodedData = "";
    let passwordHash = CryptoJS.SHA512(username);
    URLEncodedData +=`username=${username}`;
    URLEncodedData +=`&password=${passwordHash.toString(CryptoJS.enc.Hex)}`;

    const res = await fetch('/login',
        {
            method: 'POST', 
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: URLEncodedData
        }
    );

    return res;
}

export default postLogin