import React, {useContext, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Calendar from '../components/UserBookingCalendar';
import ZumbaBookingSlotsState, { ZumbaBookingSlotsContext } from '../state/zumbaBookingSlotsState';
import { getZumbaBookingSlotsWithState, signUpUserToZumbaBookingSlotWithState } from '../api/ZumbaUtils';
import '../styles/Zumba.css';

export const Zumba = () => {
  const { zbsDispatch, zbsState } = useContext(ZumbaBookingSlotsContext);

  useEffect(() => {
    getZumbaBookingSlotsWithState(zbsDispatch);
  }, [zbsState.reload]);

  return (
    <Grid container spacing={0}>
        <Grid item lg={12} md={12} xs={12}>
          <div className="hero-image-zumba"/>
          <span className="zumba-title"> Zumba </span>
        </Grid>
        <Grid item lg={4} md={2} xs={1}>
        </Grid>
        <Grid item lg={4} md={8} xs={10}>
            <div className="booking-text">
              <span>Book your Zumba class here!</span>
              <span>Zumba is a combination of salsa and aerobics involving your entire body, from your arms to your shoulders and your feet, so you will get a full-body workout. Every class feels like a party! Come join me, I guarantee you will have a blast! Classes are for individuals of any dance level! Come and express your passion for dance and creativity! New classes will be listed on a weekly basis.</span><br/>
              <ul style={{marginRight: 'auto', marginLeft: 'auto'}}>
                  <li style={{paddingRight:"0rem"}}>Be sure to wear comfortable clothing that you’re ready to sweat in</li><br/>
                  <li style={{paddingRight:"0rem"}}>Wear shoes that allow you to move easily</li><br/>
                  <li style={{paddingRight:"0rem"}}>Take a bottle of water as you’ll need to stay hydrated</li><br/>
              </ul><br/>
              <strong>
                <span>Price</span>
              </strong>
              <span>Each class is £5, paid at the class via cash or card.</span><br/>
              <span> Click to book your classes! </span>
            </div>
            <Calendar current={"Zumba"} signUpUser={signUpUserToZumbaBookingSlotWithState} dispatch={zbsDispatch} state={zbsState}/>
        </Grid>
        <Grid  item lg={4} md={2} xs={1}>
        </Grid>
    </Grid>
  );
}

const ZumbaWithState = () => {
  return (
    <ZumbaBookingSlotsState>
      <Zumba />
    </ZumbaBookingSlotsState>
  );
}

export default ZumbaWithState;

