import React from 'react';
import Grid from '@material-ui/core/Grid';

export default () => {
    return (
        <Grid container spacing={0}>
          <Grid  item lg={2} md={2} xs={1}>
          </Grid>
          <Grid style={{textAlign: 'center'}} item lg={8} md={8} xs={10}>
            <h1>Policies & Procedures</h1>
            <span>
                Vaida’s Dance Studio is committed to safeguarding and promoting the welfare of all children and expects all staff, visitors and parents to share this commitment.
                It is every dance schools statutory responsibility to safeguard and promote the welfare of children, as outlined by the DFE in its publication Keeping Children Safe in Education.
                This means that the dance school must protect children from maltreatment, prevent impairment of children’s health or development, ensure that children grow up in circumstances consistent with the provisions of safe and effective care and take action to enable all children to have the best outcomes in life. Our safe guarding officer is Vaida Bartoseviciene (Principal).
                At Vaida’s Dance Studio we ensure that all members of staff and visitors who come into contact with the children and their families is clear on the role that they play in safeguarding children. We promote an ethos where children and parents feel comfortable sharing their concerns about their safety and well being of others.
                Child protection is everybody’s responsibility and all people in the dance school community have a duty to act on any concern. If you are concerned about the safety of any child in our dance studio, you must report this to the designated safeguarding leads –Vaida Bartosevicene.
                If you are concerned about the conduct of an instructor or a volunteer in our dance studio, you must contact the Principal Vaida Bartosevicene.
                All staff members of our dance school wear a t-shirt saying “instructor” or “chaperone” and visitors are also required to wear a t-shirt which has been provided. This allows the staff, children and parents to know who the adults are in the dance school, ensuring that everyone feels safe.
                Photographs must not be taken (or recordings made) whilst on site unless authorised by the dance studio to do so.
            </span><br/><br/>
            <h1>Staff/Visitors Procedures</h1>
            <span style={{ display: "block", marginBottom: "0.1rem"}}>• All visitors must sign in at designated area.</span><br/>
            <span style={{ display: "block", marginBottom: "0.1rem"}}>• All visitors will be asked to remain under the supervision of a designated member of staff whilst on site.</span><br/>
            <span style={{ display: "block", marginBottom: "0.1rem"}}>• Visitors wishing to speak to a particular member of staff should telephone the dance studio to make an appointment, when possible, to avoid disappointment or email vaidasdancestudio@gmail.com.</span><br/>
            <span style={{ display: "block", marginBottom: "0.1rem"}}> <span style={{ display: "block", marginBottom: "0.1rem"}}>• Visitors wishing to speak to a particular member of staff should telephone the dance studio to make an appointment, when possible, to avoid disappointment or email vaidasdancestudio@gmail.com.</span><br/></span><br/>
            <h1>Child protection policy</h1>
            <span>
                Vaida’s Dance Studio fully recognises its responsibilities for child protection.
                Our policy applies to all teachers and staff working in the studio. There are three main elements to our policy:

                <span style={{ display: "block", marginBottom: "0.1rem"}}>1. Ensuring we practice safe recruitment in checking the suitability of staff to work with children – this is achieved by running a DBS (formerly CRB) check against all new employees.</span><br/>
                <span style={{ display: "block", marginBottom: "0.1rem"}}>2. Raising awareness of child protection issues and equipping children with the skills needed to keep them safe.</span><br/>
                <span style={{ display: "block", marginBottom: "0.1rem"}}>3. Establishing a safe environment in which children can learn and develop. The school will endeavor to: <br/>– Establish and maintain an environment where children feel secure, are encouraged to talk and are listened to <br/>– Ensure children know that there are adults in the school whom they can approach if they are worried.</span>
                <br/>This policy applied to both children under 18 years and vulnerable adults.<br/><br/>

                Vaida’s Dance Studio has a duty of care to safeguard all children from harm whilst involved in tuition, practice and performance DURING THE TIME THEY ARE IN CLASS UNDER SUPERVISION.<br/><br/>
                Vaida’s Dance Studio Child Protection Policy is to follow good practice guidelines. These guidelines cover physical injury, sexual abuse, emotional abuse and staff are made aware of their responsibilities which include avoiding unnecessary physical contact and/or private or unobserved situations.<br/><br/>
                Parents will be required to sign a terms and conditions form to confirm they have understood their responsibilities set out in the policy.<br/><br/>
                Also included in our T’s and C’s will be a disclaimer for all parents/guardians/carers to give their permission for photographs and/or video recordings to be taken from time to time by school staff or appointed professionals for promotional material for the school and social media. If any parent/guardian/carer objects to this, it is their responsibility to ensure that the principal is made aware.<br/><br/>
                <br/>
            </span>
            <h1>Behaviour Policy 2017</h1>
            <span>
                Standards of Behaviour expected: All members of the dance studio community are expected to:
                be responsible for their own actions and know how to behave.<br/>

                <span style={{ display: "block", marginBottom: "0.1rem"}}>•	show self-discipline and support others.</span>
                <span style={{ display: "block", marginBottom: "0.1rem"}}>•	have good manners and be polite.</span>
                <span style={{ display: "block", marginBottom: "0.1rem"}}>•	show understanding and respect towards each other and others’ property.</span>
                <span style={{ display: "block", marginBottom: "0.1rem"}}>•	display positive attitudes to studio and work which emphasises high standards.</span>
                Occasionally, parents need to be more directly involved and we believe that by working closely with pupils and parents we can achieve positive results.<br/><br/>
                Vaida’s Dance Studio are responsible for modelling good behaviour and having high expectations of all children with regard to their behaviour. The studio environment gives clear messages to the children about the extent to which they and their efforts are valued. Resources should be arranged to promote independent learning and minimise disruption. Displays should help develop self-esteem through demonstrating the value of every individual’s contribution.<br/><br/>
                Praise should be used to encourage good behaviour as well as good work.<br/><br/>

            </span>
            <h1>Anti-bullying Policy</h1>
            <span>
                Here at Vaida’s Dance Studio We do not tolerate bullying of any kind and It is expected that parents will support Vaida’s Dance Studio in encouraging their children to show respect and support Vaida’s Dance Studio authority when disciplining its pupils. Any incident of apparent bullying is investigated. DfE guidance defines bullying as “actions that are meant to be hurtful and which happen on a regular basis. Bullying can be direct or indirect.”<br/><br/>
                At Vaida’s Dance Studio we are committed to working with staff, pupils, parents and carers to create and maintain a happy, family friendly atmosphere where all forms of bullying will not be tolerated, including when bullying is motivated by prejudice or negative attitudes towards a person’s race, religion, nationality, ethnicity, disability, gender, gender identity and sexual orientation (LGBT).<br/><br/>
                Vaida’s Dance Studio anti-bullying policy has been developed with due regard for our duties and obligations under existing legislation for the safeguarding and wellbeing of all our pupils and we will take all steps to ensure their safety.<br/><br/>
                Vaida’s Dance Studio aims to build a feeling of confidence in children and parents so that they know that all reports or suspicions of bullying will be investigated.<br/><br/>
                Vaida’s Dance Studio aims to equip children with the skills, attitudes and values that will prepare them for adult life. Children who exhibit bullying behaviour may carry it into adult life. Prevention of bullying In order to avoid bullying Vaida’s Dance Studio aims to establish a positive approach to behaviour.<br/><br/>
            </span><br/><br/>
            <h1>Equal Opportunities Policy</h1>
            <span>
                Vaida’s Dance Studio actively promotes diversity and equal opportunity in all aspects of its work and seeks to prevent discrimination against any individual or group within Vaida’s Dance Studio, on the grounds of racial/ethnic heritages, religious or political belief, socio- economic and marital status, physical attributes, disablement, gender, sexual orientation and age.
            </span><br/><br/>
            <h1>Disabilities Policy</h1>
            <span>
                Vaida’s Dance Studio recognises that it has clear obligations towards all its students to ensure that they are all afford the opportunity to realize their full potential.<br/><br/>
                Vaida’s Dance Studio tries to anticipate the needs of all students who join with a disability or special need, or who become impaired during their course of study. The aim is that no one should be disadvantaged in any way.<br/><br/>
                If students have impairment, or have special requirements, they are encouraged to inform us as soon as possible (usually on the application form). We rely on the parent/student to tell us what is needed. This will give us time to check that we can meet particular needs or, if not, make sure that suitable provision is in place by the time that students arrive. This policy embraces the Disability Discrimination Act 1995.<br/><br/>
            </span><br/><br/>
            <h1>Health and Safety Policy</h1>
            <span>
                Vaida’s Dance Studio aims to keep the working environment clean, tidy and free from hazards. Our teachers endeavour to conduct dance classes in a safe and proper manner, encouraging good warm-up and cool-down practices and supplementing the syllabus exercises by keeping abreast of the most up to date thinking in dance/sport medicine.
            </span><br/><br/>
            <h1>First Aid</h1>
            <span>
                Vaida’s Dance Studio will comply with the requirements of the Health and Safety (First Aid) Regulations 1981.<br/><br/>
                First aid boxes are located throughout Vaida’s Dance Studio premises and checked on a monthly basis and where necessary the contents replenished. The minimum legal provision of a suitably stocked first aid box will always be met.
            </span><br/><br/>
            <h1>Fire Risk Assessment</h1>
            <span>
                Fire Risk Assessments have been completed for the premises which gives details of the controls in place in order to minimise risk to life in the event of fire. All employees are instructed on the action to be taken in the event of a fire or other emergency. All employees will be fully conversant with procedures for fire prevention, detection and evacuation.
            </span><br/><br/>
            <h1>Risk Assessments</h1>
            <span>
                It is Vaida’s Dance Studio policy that formal written risk assessments will be undertaken prior to commencement of any work, which is potentially harmful to health.
            </span><br/><br/>
            <h1>Training</h1>
            <span>
                All new employees will receive health and safety induction training. A record of this training will be maintained within the HR Department. Periodic refresher training will be provided and records of such training will be maintained within the HR Department.<br/>
                Departmental Managers will ensure that all personnel under their direct supervision have received suitable and sufficient training for the tasks that they will be expected to undertake. Where new processes/procedures are introduced training will be provided as required.<br/>
                Training in respect of fire and emergency evacuation procedures will be mandatory for all employees and will be practised on a regular basis.
            </span><br/><br/>
            <h1>Data Protection Act</h1>
            <span>
                Vaida’s Dance Studio keeps records of pupils on a computer database with password protection for the purpose of efficiency and consistency of administration. They can be viewed by you at any time. We will not share any of your personal details without your permission, aside from where the law allows. This complies with the Data Protection Act 1998.
            </span><br/><br/>
            <h1>Browsing our website</h1>
            <span>
                By simply browsing our site, we do not collect any personally-identifiable information about you.<br/><br/>
            </span><br/><br/>
            <h1>Emailing us or enquiring about a class</h1>
            <span>
                If you email us or use the contact/book a class forms, you will share with us certain information. This will include your email address and could also include your name, your child’s name, their date of birth and any other details you tell us within the message. Only key members of our staff can read these messages and we will only use the details you provide to enable us to fulfil your request (for example, giving you details about a suitable class).
            </span><br/><br/>
            <h1>Registering your child</h1>
            <span>
                If you register your child for our classes, we will collect personal information about you and your child. This will include your name, your address, your email address and phone number as well as your child’s name, their date of birth, gender and the date they started classes. You may also tell us about any previous dance experience your child has had, any exams they have taken and if they have any needs or conditions we should be aware of (for example allergies, disabilities or any medication they might need to take whilst dancing). With these details we will also store a record of exams your child takes with us, invoices we issue (including when and how they were paid) and any items you order from us (for example uniform or show tickets). We store this information securely within the UK and only key members of staff have access to it. We use the information to provide our services to you, keep you informed, and help us tailor our services better to the needs of our customers. We DO NOT share your details with any third party companies.
            </span><br/><br/>
          </Grid>
          <Grid item lg={2} md={2} xs={1}>
          </Grid>

        </Grid>
    );
}