import React,{useState, useEffect } from 'react';
import {
    Link
  } from "react-router-dom";
import LogoSVG from '../../images/Logo.svg';
import BurgerMenuSVG from '../../images/BurgerMenu.svg';
import "../../styles/Header.css"

// Hook
function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
  
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      
      window.addEventListener("resize", handleResize);
      
      handleResize();
      
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    return windowSize;
  }


const NAV_HREFS = [
    { pathname: "/zumba", content: "Zumba"},
    { pathname: "/ballet", content: "Adult Ballet"},
    { pathname: "/kids-ballet", content: "Kids Ballet"}
]
const ActiveLink = ({to, children}) => {
    return (<Link to={to} className="active">{children}</Link>);
}

const BurgerMenu = () => {
    let [open, setOpen] = useState(false);

    return (
        <div className="burger-container">
            <span className="header-title"> 
                <Link to="/"><img src={LogoSVG}/></Link>
            </span>
            <div>
                <img src={BurgerMenuSVG} onClick={() => {setOpen(!open)}} className="burger-icon"/>
            </div>
            {
                <ul className={open? "link-container__open" : "link-container"}>
                    {NAV_HREFS.map(data => <li><Link onClick={() => setOpen(!open)} to={data.pathname}>{data.content}</Link></li>)}
                </ul>
            }
        </div>
    );
}

const Header = () => {
    let screenSize = useWindowSize().width;

    return (
        screenSize > 600? (
            <div className="header-container">
                <span className="header-title">
                    <Link to="/"><img src={LogoSVG}/></Link>
                </span>
                <ul> 
                    {NAV_HREFS.map(data =><li><Link to={data.pathname}>{data.content}</Link></li>)}
                </ul>
            </div> ) : (
                <BurgerMenu/>
            )
    );
}

export default Header;