import React from 'react';
import Grid from '@material-ui/core/Grid';
import { formDownload } from '../api/Utils';
import '../styles/KidsBallet.css';

export const KidsBallet = () => {
  return (
    <Grid container spacing={0}>
        <Grid item lg={12} md={12} xs={12}>
          <div className="hero-image-kids-ballet"/>
          <span className="kids-ballet-title"> Kids Ballet </span>
        </Grid>
        <Grid  item lg={2} md={2} xs={0}>
        </Grid>
        <Grid item lg={8} md={8} xs={12}>
          <div style={{width: "55vw", marginRight: 'auto',  marginLeft: 'auto'}} className="booking-text">
          <span>Book your Kids Ballet class here!</span>
          <span>Give children a first taste of the magical world of ballet. Ballet classes can have a dynamic impact on children’s self-esteem, education, and future careers. Classes are taught in a fun and positive environment to boost each child’s confidence and happiness.</span><br/>
          </div>
        </Grid>
        <Grid  item lg={2} md={2} xs={0}>
        </Grid>
        <Grid item lg={2} md={2} xs={0}>
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <div className="booking-text">
              <span>Uniform</span>
              <span >
                <strong>Girls Ballet (Pre-School, Primary)</strong>
                <ul style={{marginRight: 0}}>
                  <li style={{paddingRight:"0rem"}}>Lilac skirted leotard</li><br/>
                  <li style={{paddingRight:"0rem"}}>White ankle socks</li><br/>
                  <li style={{paddingRight:"0rem"}}>Pink canvas split sole ballet shoes</li><br/>
                  <li style={{paddingRight:"0rem"}}>Lilac Knitted cross over cardigan</li><br/>
                  <li style={{paddingRight:"0rem"}}>Hair in a bun</li><br/>
                </ul>
                <strong>Grade 1</strong>
                <ul style={{marginRight: 0}}>
                  <li style={{paddingRight:"0rem"}}>Mulberry leotard</li><br/>
                  <li style={{paddingRight:"0rem"}}>Pink convertible ballet tights</li><br/>
                  <li style={{paddingRight:"0rem"}}>Pink canvas split sole ballet shoes</li><br/>
                  <li style={{paddingRight:"0rem"}}>Mulberry knitted cross over cardigan</li><br/>
                  <li style={{paddingRight:"0rem"}}>Hair in a bun</li><br/>
                </ul>
                <strong>Boys</strong>
                <ul style={{marginRight: 0}}>
                  <li style={{paddingRight:"0rem"}}>White leotard</li><br/>
                  <li style={{paddingRight:"0rem"}}>Black or navy ballet shorts</li><br/>
                  <li style={{paddingRight:"0rem"}}>White ankle socks</li><br/>
                  <li style={{paddingRight:"0rem"}}>White split sole ballet shoes</li><br/>
                </ul>
              </span>
          </div>
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
            <div className="booking-text">
                <span>Time Table</span>
                <strong>Wednesday</strong>
                <span >
                  Pre-school Ballet (3-5 year) - 16:30-17:00<br/>
                  Primary Ballet (5-7 year) - 17:10-17:40<br/>
                  Grade 1 Ballet (7-10 year) - 17:50-18:30<br/>
                </span>
                <div className="booking-text">
                  <span>Term Dates</span>
                  <span >
                    <strong> Autumn Term 2020 </strong><br/>
                    Tuesday 8th September – Friday 23rd October<br/>
                    Half Term Monday 26th October – Friday 30th October<br/>
                    Return on Tuesday 3rd November – Friday 18th December<br/><br/>
                    <strong> Spring Term 2021 </strong><br/>
                    Tuesday 8th September – Friday 23rd October<br/>
                    Half Term Monday 15th February – Friday 19th February<br/>
                    Return on Tuesday 23rd February – Tuesday 30th March<br/>
                  </span>
                </div>
            </div>
          </Grid>
          <Grid  item lg={2} md={2} xs={0}>
          </Grid>

          <Grid  item lg={2} md={2} xs={0}>
          </Grid>
          <Grid item lg={8} md={8} xs={12}>
            <div style={{width: "55vw", marginRight: 'auto',  marginLeft: 'auto'}} className="booking-text">
                <span>Registration</span>
                <span>In order for your child to attend classes their information and details must be first collected. please download and fill out the form below. Once completed, please send an email to <strong>vaidasdancestudio@gmail.com</strong> with the form attached  </span>
                <div onClick={async () => formDownload()} className="download-button"> 
                  Download Form
                </div>
            </div>
          </Grid>
          <Grid  item lg={2} md={2} xs={0}>
          </Grid>
        
    </Grid>
  );
}

export default KidsBallet;
