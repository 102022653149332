import React, { useContext, useState }  from 'react';
import Moment from 'moment';
import BookingDayEntry from './BookingDayEntry';
import Loader from "../Loader"
import '../../styles/BookingWeekList.css';

const compareTimes = (slotA, slotB) => {
    if(Moment(slotA.StartTime, "HH:mm DD-MM-YYYY").isBefore(Moment(slotB.StartTime, "HH:mm DD-MM-YYYY"), 'hours')) {
        return -1;
    }
    if(Moment(slotA.StartTime, "HH:mm DD-MM-YYYY").isAfter(Moment(slotB.StartTime, "HH:mm DD-MM-YYYY"), 'hours')) {
        return 1;
    }
    if(Moment(slotA.StartTime, "HH:mm DD-MM-YYYY").isSame(Moment(slotB.StartTime, "HH:mm DD-MM-YYYY"), 'hours')) {
        return 0;
    }
}
const NUM_OF_DAYS = 7;
/*
 1. GET CURRENT DAY
 2. FOR DAY CHECK IF A SLOTS EXISTS AND AT WHAT TIME
 3. IF EXISTS, RENDER IN INFO FOR AVAILABLE CLASSES
 4. IF NOT SAYS THERE IS NO CLASSES FOR THE DAY
*/

const BookingWeekList = ({current, dispatch, state, signUpUser}) => {
    const {slots, loading} = state;
    const [slotsSelected, setSelectedSlots] = useState([]);
    let children = [];
    // let weekSlots = null;


    if(slots) {
        let filteredSlots = slots.filter(slot => {
            return Moment().diff(Moment(slot.StartTime, "HH:mm DD-MM-YYYY"), 'minutes') < 0;
        });
        filteredSlots = slots.filter(slot =>  slot.SlotsLeft > 0 );

        if(filteredSlots.length > 0) {
            filteredSlots = filteredSlots.sort(compareTimes);
            
            let recordedDays = [];
            let a = 0;
            let i =0;
            while(a < filteredSlots.length && recordedDays.length < 7) {
                if(filteredSlots.length > 0 && recordedDays.filter(dateString => dateString === Moment(filteredSlots[a].StartTime, "HH:mm DD-MM-YYYY").format("DD-MM-YYYY")).length < 1) {
                    let daySlots = filteredSlots.filter(slot => {
                        return Moment(slot.StartTime, "HH:mm DD-MM-YYYY").isSame(Moment(filteredSlots[a].StartTime, "HH:mm DD-MM-YYYY"), 'days');
                    });
    
                    let date = Moment(daySlots[0].StartTime, "HH:mm DD-MM-YYYY").format('dddd MMMM Do');
                    let sortedDaySlots = daySlots.sort(compareTimes);
                    let startTime =  Moment(sortedDaySlots[0].StartTime, "HH:mm DD-MM-YYYY").format('ha');
                    let endTime =  Moment(sortedDaySlots[sortedDaySlots.length - 1].EndTime, "HH:mm DD-MM-YYYY").format('mm') === "00"? 
                        Moment(sortedDaySlots[sortedDaySlots.length - 1].EndTime, "HH:mm DD-MM-YYYY").format('ha') : 
                        Moment(sortedDaySlots[sortedDaySlots.length - 1].EndTime, "HH:mm DD-MM-YYYY").add(1, 'hours').format('ha');
                    let timeRange = (
                        "" +
                        startTime  
                        + 
                        " - " + 
                        endTime
                    );
    
                    children.push(
                        <div className={"booking-week-list-card" +  (i % 2 === 0? " even": "")} >
                            <span className="card-date"> {date} </span>
                            {/* <span className="available-class-text">Classes available from {timeRange} </span> */}
                            <span className="available-class-text"> Classes available </span>
                            <div className="open-button">
                                <button onClick={() => {setSelectedSlots(sortedDaySlots)}}>Book</button>
                            </div>
                        </div>
                    );
                    recordedDays.push(Moment(filteredSlots[a].StartTime, "HH:mm DD-MM-YYYY").format("DD-MM-YYYY"));
                    i++;
                } 
               
                a++;
            }
        } else {
            children.push(
                <div className={"booking-week-list-card even"} >
                    <span className="card-date">  </span>
                    <span className="available-class-text"> No classes available </span>
                    <div className="open-button">
                            <button disabled>Book</button>
                    </div>
                </div>
            );
        }
    
      
    } else {
        children.push(
            <div className={"booking-week-list-card even"} >
                <span className="card-date">  </span>
                <span className="available-class-text"> No classes available </span>
                <div className="open-button">
                        <button disabled>Book</button>
                </div>
            </div>
        );
        
    }
    /*
        for(let i = 0; i < NUM_OF_DAYS; i++) {
        let weekDay = Moment().add(i, 'days');
        let date = weekDay.format('dddd MMMM Do');

        if(weekSlots) {
            let slotsOnWeekDay = weekSlots.filter(slot => Moment(slot.StartTime, "HH:mm DD-MM-YYYY").isSame(weekDay, 'days'));
            let sortedSlotsOnWeekDay = slotsOnWeekDay.sort(compareTimes);

            if(sortedSlotsOnWeekDay.length > 0) {

                let timeRange = (
                    "" +  
                    Moment(sortedSlotsOnWeekDay[0].StartTime, "HH:mm DD-MM-YYYY").format('ha') + 
                    " - " + 
                    Moment(sortedSlotsOnWeekDay[sortedSlotsOnWeekDay.length - 1].EndTime, "HH:mm DD-MM-YYYY").format('ha')
                );
               
                children.push(
                    <div className={"booking-week-list-card" +  (i % 2 === 0? " even": "")} >
                        <span className="card-date"> {date} </span>
                        <span className="available-class-text">Classes available from {timeRange} </span>
                        <div className="open-button">
                            <button onClick={() => {setSelectedSlots(slotsOnWeekDay)}}>Book</button>
                        </div>
                    </div>
                );
            } else {
                children.push(
                    <div className={"booking-week-list-card" +  (i % 2 === 0? " even": "")} >
                        <span className="card-date"> {date} </span>
                        <span className="available-class-text"> No classes available </span>
                        <div className="open-button">
                            <button disabled>Book</button>
                        </div>
                    </div>
                );
            }
        } else {
            children.push(
                <div className={"booking-week-list-card" + (i % 2 === 0? " even": "")} >
                    <span className="card-date"> {date} </span>
                    <span className="available-class-text"> No classes available </span>
                    <div className="open-button">
                            <button disabled>Book</button>
                    </div>
                </div>
            );
        }

    }
    */
    //

    
//
    return (
        <div>
            <div className="booking-week-list-container">
                <span className="calendar-title"> 
                    Booking Calendar
                </span>
                <div className="booking-week-list-card-container">
                    {children}
                    {loading? <Loader/> : <></>}
                </div>
            </div>
            {slotsSelected.length > 0? <BookingDayEntry signUpUser={signUpUser} dispatch={dispatch} setSlots={setSelectedSlots} current={current} slots={slotsSelected}/> : <></>}
        </div>
    );
}

export default BookingWeekList;