import React, { useReducer } from 'react';
import Moment from 'moment';
export const BalletBookingSlotsContext = React.createContext();


export const NUM_OF_DAYS = 28;
export const NUM_OF_HOURS = 12;
export const TIME_OFFSET = 9;
export const MAX_SLOTS = 20;

const getTimeCellIndex = () => {
    let index = {};

    for (let i=0; i <= NUM_OF_HOURS; i++) {
        for (let j=0; j < NUM_OF_DAYS; j++) {
            for(let k =0; k < 4; k++) {
                let key = `${Moment([TIME_OFFSET+i, k*15 ],'HH:mm').format('HH:mm')} ${Moment().add(j, 'days').format("DD-MM-YYYY")}`;
                index[key] = [i*4+k,j];
            }
           
        }
    }

    return index;
}

const getCellTimeIndex = () => {
    let index = {};
    
    for(let k =0; k < 4; k++) {
        for (let i=0; i <= NUM_OF_HOURS; i++) {
            index[i*4+k] = {};
            for (let j=0; j < NUM_OF_DAYS; j++) {

                let value = `${Moment([TIME_OFFSET+i, k*15 ],'HH:mm').format('HH:mm')} ${Moment().add(j, 'days').format("DD-MM-YYYY")}`;
                index[i*4+k][j] = value;
            }
        }
    }

    return index;
}


const getCellSlotIndex = (slots, cellTimeIndex) => {
    let cellSlotIndex = {};

    Object.entries(cellTimeIndex).forEach(([cellx, celly]) => {
        if(typeof cellSlotIndex[cellx] === "undefined") {
            cellSlotIndex[cellx] = {};
        }

         Object.entries(celly).forEach(([cell, value]) => {
            slots.forEach((slot) => {
                if( Moment(value, "HH:mm DD-MM-YYYY")
                    .isSameOrAfter(Moment(slot.StartTime, "HH:mm DD-MM-YYYY")) 
                    && 
                    Moment(value, "HH:mm DD-MM-YYYY")
                    .isBefore(Moment(slot.EndTime, "HH:mm DD-MM-YYYY"))
                ) {
                    cellSlotIndex[cellx][cell] = slot.ID;
                }
            });
            
            if(typeof cellSlotIndex[cellx][cell] === "undefined") {
                cellSlotIndex[cellx][cell] = null;
            }
        })
    });

    return cellSlotIndex;
}

const initState = {
    slots: null,
    cellsInEdit: null,
    endCells: null,
    loading: false,
    reload: false,
    error: undefined,
    timeCellIndex: getTimeCellIndex(),
    cellTimeIndex: getCellTimeIndex(),
    cellSlotIndex: null
}


const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_STATE_VALUES':
            return { ...state, ...action.payload };
        case 'QUERY_COMPLETED':
            let reformattedSlots = action.payload.map(slot => 
                ({
                    ...slot, 
                    StartTime: Moment(slot.StartTime, "YYYY-MM-DD HH:mm").format("HH:mm DD-MM-YYYY"), 
                    EndTime: Moment(slot.EndTime, "YYYY-MM-DD HH:mm").format("HH:mm DD-MM-YYYY")
                })
            );

            return {
                ...state, 
                loading: false, 
                error: undefined, 
                reload: false,
                slots: reformattedSlots,
                cellSlotIndex: getCellSlotIndex(reformattedSlots, state.cellTimeIndex)
            };

        case 'QUERY_ERROR':
            return { ...state, loading: false, error: action.payload };
        case 'QUERY_LOADING':
            return { ...state, loading: true };
        default:
            return state;
    }
  };


const useBalletBookingSlotsState = ({ children }) => {
    const [bbsState, bbsDispatch] = useReducer(reducer, initState);
    const Store = { bbsState, bbsDispatch };
  
    return (
        <BalletBookingSlotsContext.Provider value={Store}>
            {children}
        </BalletBookingSlotsContext.Provider>
    );
};
  
export default useBalletBookingSlotsState;