import React from 'react';
import '../styles/PrivacyPolicy.css';


export default () => {
    return (
      <div className="content-body-policy">
      <div data-custom-class="title"><strong>PRIVACY NOTICE</strong></div>
      <p><strong><span data-custom-class="subtitle">Last updated September 02, 2020</span></strong></p>
      <p><span data-custom-class="body_text">Thank you for choosing to be part of our community at Vaida's Dance Studio (&ldquo;<strong>Company</strong>&rdquo;, &ldquo;<strong>we</strong>&rdquo;, &ldquo;<strong>us</strong>&rdquo;, or &ldquo;<strong>our</strong>&rdquo;). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at vaidasdancestudio@gmail.com.</span></p>
      <p><span data-custom-class="body_text">When you visit our website vaidasdancestudio.com (the "<strong>Website</strong>"), and more generally, use any of our services (the "<strong>Services</strong>", which include the <span data-custom-class="body_text">Website</span></span><span data-custom-class="body_text">), we appreciate that you are trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy noticethat you do not agree with, please discontinue use of our Services immediately.</span></p>
      <p><span data-custom-class="body_text">This privacy notice applies to all information collected through our Services (which, as described above, includes our Website</span><span data-custom-class="body_text">), as well as any related services, sales, marketing or events.</span></p>
      <p><strong><span data-custom-class="body_text">Please read this privacy notice carefully as it will help you understand what we do with the information that we collect.</span></strong></p>
      <p><strong><span data-custom-class="heading_1">WHAT INFORMATION DO WE COLLECT?</span></strong></p>
      <p><strong><span data-custom-class="heading_2"><strong><span data-custom-class="heading_2"><br />Personal information you disclose to us</span></strong></span></strong></p>
      <p><strong><span data-custom-class="body_text"><em>In Short: </em></span></strong><span data-custom-class="body_text"><em>We collect information that you provide to us.</em></span></p>
      <p><span data-custom-class="body_text">We collect personal information that you voluntarily provide to us when you </span>express an interest in obtaining information about us or our products and Services, when you participate in activities on the <span data-custom-class="body_text">Website</span> or otherwise when you contact us.</p>
      <p><span data-custom-class="body_text">The personal information that we collect depends on the context of your interactions with us and the <span data-custom-class="body_text">Website</span>, the choices you make and the products and features you use. The personal information we collect may include the following:</span></p>
      <p><span data-custom-class="body_text"><strong>Personal Information Provided by You.</strong> We collect names; phone numbers; email addresses; and other similar information.</span></p>
      <div><span data-custom-class="body_text">All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.</span></div>
      <p><strong><span data-custom-class="heading_2"><strong><span data-custom-class="heading_2"><br />Information automatically collected</span></strong></span></strong></p>
      <div><span data-custom-class="body_text"><em><strong>In Short: </strong>Some information &mdash; such as your Internet Protocol (IP) address and/or browser and device characteristics &mdash; is collected automatically when you visit our <span data-custom-class="body_text"><span data-custom-class="body_text">Website</span></span>.</em></span></div>
      <div><span data-custom-class="body_text">We automatically collect certain information when you visit, use or navigate the <span data-custom-class="body_text">Website</span>. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about who and when you use our <span data-custom-class="body_text">Website</span> and other technical information. This information is primarily needed to maintain the security and operation of our <span data-custom-class="body_text">Website</span>, and for our internal analytics and reporting purposes.</span></div>
      <div><span data-custom-class="body_text">Like many businesses, we also collect information through cookies and similar technologies. </span>&nbsp;</div>
      <div><span data-custom-class="body_text">The information we collect includes:</span></div>
      <ul>
        <li><span data-custom-class="body_text"><em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic usage and performance information our servers automatically collect when you access or use our <span data-custom-class="body_text">Website</span> and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information about your activity in the <span data-custom-class="body_text">Website</span> (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings).</span></li>
      </ul>
      <ul>
        <li><span data-custom-class="body_text"><em>Device Data.&nbsp;</em>We collect device data such as information about your computer, phone, tablet or other device you use to access the&nbsp;<span data-custom-class="body_text">Website</span>. Depending on the device used, this device data may include information such as your IP address (or proxy server), device application identification numbers, location, browser type, hardware model Internet service provider and/or mobile carrier, operating system configuration&nbsp;information.</span></li>
      </ul>
      <ul>
        <li><span data-custom-class="body_text"><em>Location Data.</em> We collect information data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type of settings of the device you use to access the <span data-custom-class="body_text">Website</span>. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Locations settings on your device. Note however, if you choose to opt out, you may not be able to use certain aspects of the Services.</span></li>
      </ul>
      <div>
        <p><strong><span data-custom-class="heading_1">HOW DO WE USE YOUR INFORMATION?</span></strong></p>
        <p><span data-custom-class="body_text"><em><strong>In Short: </strong>We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</em></span></p>
        <p><span data-custom-class="body_text">We use personal information collected via our <span data-custom-class="body_text">Website</span> for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.</span></p>
        <p><span data-custom-class="body_text">We use the information we collect or receive:</span></p>
        <ul>
          <li><span data-custom-class="body_text"><strong><span data-custom-class="body_text">To facilitate account creation and logon process.</span></strong><span data-custom-class="body_text">&nbsp;If you choose to link your account with us to a third-party account (such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract. </span></span><br /><br /></li>
          <li><strong><span data-custom-class="body_text">To post testimonials.</span></strong><span data-custom-class="body_text">&nbsp;We post testimonials on our <span data-custom-class="body_text">Website</span> that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and the consent of the testimonial. If you wish to update, or delete your testimonial, please contact us at vaidasdancestudio@gmail.com and be sure to include your name, testimonial location, and contact information.</span><span data-custom-class="body_text"><br /><br /></span></li>
          <li><strong><span data-custom-class="body_text">Request feedback.</span></strong><span data-custom-class="body_text">&nbsp;We may use your information to request feedback and to contact you about your use of our <span data-custom-class="body_text">Website</span>.</span><span data-custom-class="body_text"><br /><br /></span></li>
          <li><strong><span data-custom-class="body_text">To enable user-to-user communications.</span></strong><span data-custom-class="body_text">&nbsp;We may use your information in order to enable user-to-user communications with each user's consent.</span><span data-custom-class="body_text"><br /><br /></span></li>
          <li><span data-custom-class="body_text"><strong>To manage user accounts</strong>. We may use your information for the purposes of managing our account and keeping it in working order.</span></li>
        </ul>
        <ul>
          <li><span data-custom-class="body_text"><strong><span data-custom-class="body_text">To send administrative information to you.&nbsp;</span></strong><span data-custom-class="body_text">We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.</span><br /><br /></span></li>
          <li><strong><span data-custom-class="body_text">To protect our Services.</span></strong><span data-custom-class="body_text">&nbsp;We may use your information as part of our efforts to keep our <span data-custom-class="body_text">Website</span> safe and secure (for example, for fraud monitoring and prevention).</span><span data-custom-class="body_text"><br /><br /></span></li>
          <li><strong><span data-custom-class="body_text">To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract.</span></strong><span data-custom-class="body_text"><br /><br /></span></li>
          <li><strong><span data-custom-class="body_text">To respond to legal requests and prevent harm.&nbsp;</span></strong><span data-custom-class="body_text">If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.</span></li>
        </ul>
        <ul>
          <li><strong><span data-custom-class="body_text">Fulfill and manage your orders.</span></strong><span data-custom-class="body_text">&nbsp;We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the <span data-custom-class="body_text">Website</span>.</span><span data-custom-class="body_text"><br /><br /></span></li>
          <li><strong><span data-custom-class="body_text">Administer prize draws and competitions.&nbsp;</span></strong><span data-custom-class="body_text">We may use your information to administer prize draws and competitions when you elect to participate in our competitions.</span><span data-custom-class="body_text"><br /><br /></span></li>
          <li><span data-custom-class="body_text"><strong>To deliver and facilitate delivery of services to the user.</strong> We may use your information to provide you with the requested service.</span><span data-custom-class="body_text"><br /><br /></span></li>
          <li><span data-custom-class="body_text"><strong>To respond to user inquiries/offer support to users.</strong> We may use your information to respond to your inquiries and solve any potential issues you might have with the use of our Services.</span></li>
        </ul>
        <ul>
          <li><strong><span data-custom-class="body_text">For other business purposes.</span></strong><span data-custom-class="body_text">&nbsp;We may use your information for other business purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our <span data-custom-class="body_text">Website</span>, products, marketing and your experience.&nbsp;</span><span data-custom-class="body_text">We may use and store this information in aggregated and anonymized form so that it is not associated with individual end users and does not include personal information. We will not use identifiable personal information without your consent.</span></li>
        </ul>
        <p><strong><span data-custom-class="heading_1">WILL YOUR INFORMATION BE SHARED WITH ANYONE?</span></strong></p>
        <p><strong><em><span data-custom-class="body_text">In Short:</span>&nbsp;</em>&nbsp;</strong><em><span data-custom-class="body_text">We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</span></em></p>
        <div><span data-custom-class="body_text">We may process or share your data that we hold based on the following legal basis:</span></div>
        <ul>
          <li><span data-custom-class="body_text"><strong>Consent:</strong> We may process your data if you have given us specific consent to use your personal information in a specific purpose.<br /><br /></span></li>
          <li><span data-custom-class="body_text"><strong>Legitimate Interests:</strong> We may process your data when it is reasonably necessary to achieve our legitimate business interests.<br /><br /></span></li>
          <li><span data-custom-class="body_text"><strong>Performance of a Contract:&nbsp;</strong>Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.<br /><br /></span></li>
          <li><span data-custom-class="body_text"><strong>Legal Obligations:</strong> We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).<br /><br /></span></li>
          <li><span data-custom-class="body_text"><strong>Vital Interests:</strong> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</span></li>
        </ul>
        <p><span data-custom-class="body_text">More specifically, we may need to process your data or share your personal information in the following situations:</span></p>
        <ul>
          <li><strong><span data-custom-class="body_text">Business Transfers.</span></strong><span data-custom-class="body_text">&nbsp;We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span></li>
        </ul>
        <ul>
          <li><strong><span data-custom-class="body_text">Vendors, Consultants and Other Third-Party Service Providers.</span></strong><span data-custom-class="body_text">&nbsp;We may share your data with third-party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the <span data-custom-class="body_text">Website</span>, which will enable them to collect data on our behalf about how you interact with our <span data-custom-class="body_text">Website</span> over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content, pages or features, and better understand online activity. Unless described in this notice, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes. We have contracts in place with our data processors, which are designed to help safegaurd your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any organization apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct.</span></li>
        </ul>
        <div>
          <div>
            <div>
              <div>
                <div>
                  <div>
                    <p><strong><span data-custom-class="heading_1">WHO WILL YOUR INFORMATION BE SHARED WITH?</span></strong></p>
                  </div>
                  <div><strong><em><span data-custom-class="body_text">In Short:</span>&nbsp;</em>&nbsp;</strong><em><span data-custom-class="body_text">We only share information with the following third parties.</span></em></div>
                  <div>&nbsp;</div>
                  <div><span data-custom-class="body_text">We only share and disclose your information with the following third parties. We have categorized each party so that you may easily understand the purpose of our data collection and processing practices. If we have processed your data based on your consent and you wish to revoke your consent, please contact us using the contact details provided in the section below titled "</span><a href="#contact" data-custom-class="link">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a><span data-custom-class="body_text">".</span>
                    <div>
                      <div><strong><span data-custom-class="heading_1">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></strong></div>
                      <p><strong><em><span data-custom-class="body_text">In Short:</span>&nbsp;</em>&nbsp;</strong><em><span data-custom-class="body_text">We may use cookies and other tracking technologies to collect and store your information.</span></em></p>
                      <p><span data-custom-class="body_text">We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</span></p>
                      <p><strong><span data-custom-class="heading_1">DO WE USE GOOGLE MAPS?</span></strong></p>
                      <p><strong><em><span data-custom-class="body_text">In Short:</span>&nbsp;</em>&nbsp;</strong><em><span data-custom-class="body_text">Yes, we use Google Maps for the purpose of providing better service.</span></em></p>
                      <p><span data-custom-class="body_text">This <span data-custom-class="body_text"><span data-custom-class="body_text">Website</span></span> uses Google Maps APIs which is subject to Google's Terms of Service. You may find the Google Maps APIs Terms of Service&nbsp;</span><span data-custom-class="body_text"><a href="https://developers.google.com/maps/terms" target="_blank" rel="noopener" data-custom-class="link">here</a>. To find out more about Google&rsquo;s Privacy Policy, please refer to this&nbsp;<a href="https://policies.google.com/privacy" target="_blank" rel="noopener" data-custom-class="link">link</a></span><span data-custom-class="body_text">.</span></p>
                      <p><strong><span data-custom-class="heading_1">HOW LONG DO WE KEEP YOUR INFORMATION?</span></strong></p>
                      <p><strong><em><span data-custom-class="body_text">In Short:</span>&nbsp;</em>&nbsp;</strong><em><span data-custom-class="body_text">We keep your information for as long as necessary to fulfill the purposes outlined in this privacy noticeunless otherwise required by law.</span></em></p>
                      <p><span data-custom-class="body_text">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than 1 year.</span></p>
                      <p><span data-custom-class="body_text">When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</span></p>
                      <p><strong><span data-custom-class="heading_1">HOW DO WE KEEP YOUR INFORMATION SAFE?</span></strong></p>
                      <p><strong><em><span data-custom-class="body_text">In Short:</span>&nbsp;</em>&nbsp;</strong><em><span data-custom-class="body_text">We aim to protect your personal information through a system of organizational and technical security measures.</span></em></p>
                      <p><span data-custom-class="body_text">We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our <span data-custom-class="body_text"><span data-custom-class="body_text">Website</span></span> is at your own risk. You should only access the <span data-custom-class="body_text"><span data-custom-class="body_text">Website</span></span> within a secure environment.</span></p>
                      <p><strong><span data-custom-class="heading_1">WHAT ARE YOUR PRIVACY RIGHTS?</span></strong></p>
                      <p><strong><em><span data-custom-class="body_text">In Short:</span>&nbsp;</em>&nbsp;</strong><span data-custom-class="body_text"><em>In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</em></span></p>
                      <p><span data-custom-class="body_text">In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the</span>&nbsp;<span data-custom-class="body_text"><a href="#contact" data-custom-class="link">contact details</a></span><span data-custom-class="body_text">&nbsp;provided below. We will consider and act upon any request in accordance with applicable data protection laws.</span></p>
                      <p><span data-custom-class="body_text">If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</span></p>
                      <p><span data-custom-class="body_text">If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here:<span data-custom-class="body_text"><a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" target="_blank" rel="noopener noreferrer" data-custom-class="link">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a></span></span><span data-custom-class="body_text">.</span></p>
                      <p><span data-custom-class="body_text">If you are resident in Switzerland, the contact details for the data protection authorities are available here: <span data-custom-class="body_text"><a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank" rel="noopener noreferrer" data-custom-class="link">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.</span></span></p>
                      <p><strong><u><span data-custom-class="body_text">Cookies and similar technologies:</span></u>&nbsp;</strong><span data-custom-class="body_text">Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our <span data-custom-class="body_text"><span data-custom-class="body_text"><span data-custom-class="body_text">Website</span></span></span>. To opt-out of interest-based advertising by advertisers on our <span data-custom-class="body_text"><span data-custom-class="body_text"><span data-custom-class="body_text">Website</span></span></span></span><span data-custom-class="body_text">&nbsp;visit</span>&nbsp;<span data-custom-class="body_text"><a href="http://www.aboutads.info/choices/" target="_blank" rel="noopener noreferrer" data-custom-class="link">http://www.aboutads.info/choices/</a></span><span data-custom-class="body_text">. </span></p>
                      <p><strong><span data-custom-class="heading_1">CONTROLS FOR DO-NOT-TRACK FEATURES</span></strong></p>
                      <p><span data-custom-class="body_text">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (&ldquo;DNT&rdquo;) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</span></p>
                      <p><strong><span data-custom-class="heading_1">DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></strong></p>
                      <p><strong><em><span data-custom-class="body_text">In Short:</span>&nbsp;</em>&nbsp;</strong><em><span data-custom-class="body_text">Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</span></em></p>
                      <p><span data-custom-class="body_text">California Civil Code Section 1798.83, also known as the &ldquo;Shine The Light&rdquo; law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</span></p>
                      <p><span data-custom-class="body_text">If you are under 18 years of age, reside in California, and have a registered account with <span data-custom-class="body_text"><span data-custom-class="body_text">the Website</span></span>, you have the right to request removal of unwanted data that you publicly post on the <span data-custom-class="body_text"><span data-custom-class="body_text">Website</span></span>. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the <span data-custom-class="body_text"><span data-custom-class="body_text">Website</span></span>, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.). </span></p>
                    </div>
                    <p><strong><span data-custom-class="heading_1">DO WE MAKE UPDATES TO THIS NOTICE?</span></strong></p>
                    <p><strong><em><span data-custom-class="body_text">In Short:</span>&nbsp;</em>&nbsp;</strong><em><span data-custom-class="body_text">Yes, we will update this notice as necessary to stay compliant with relevant laws.</span></em></p>
                    <p><span data-custom-class="body_text">We may update this privacy noticefrom time to time. The updated version will be indicated by an updated &ldquo;Revised&rdquo; date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy noticefrequently to be informed of how we are protecting your information.</span></p>
                    <p><strong><span id="contact"data-custom-class="heading_1">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></strong></p>
                    <p><span data-custom-class="body_text">If you have questions or comments about this notice, you may email us at vaidasdancestudio@gmail.com or by post to:</span></p>
                    <div><span data-custom-class="body_text">Vaida's Dance Studio</span>&nbsp;</div>
                    <div><span data-custom-class="body_text">Beechen Hall, 9 Wildfell Cl</span></div>
                    <div><span data-custom-class="body_text">Chatham, Kent ME5 9RU</span></div>
                    <div><span data-custom-class="body_text">United Kingdom</span></div>
                    <p><strong><span data-custom-class="heading_1">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></strong></p>
                    <div><span data-custom-class="body_text">Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please submit a request form by clicking <span data-custom-class="body_text"><a href="https://app.termly.io/notify/dc671ee4-b078-4e4d-a8e2-3c07363b3f13" target="_blank" rel="noopener noreferrer" data-custom-class="link">here</a></span>. We will respond to your request within 30 days.</span></div>
                  </div>
                  <div>This privacy policy was created using <a href="https://termly.io/products/privacy-policy-generator/?ftseo">Termly&rsquo;s Privacy Policy Generator</a>.</div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}