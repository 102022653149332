import React, { useReducer } from 'react';

export const UserContext = React.createContext();

function getCookie(name) {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");
    
    // Loop through the array elements
    for(var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        
        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if(name == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }
    
    // Return null if not found
    return null;
}

const initState = {
    isAuthenticated: (() => getCookie("authenticated") === 'true')(),
    loading: false, 
    error: undefined, 
}


const reducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN_COMPLETED':
            return {
                ...state,
                isAuthenticated: getCookie("authenticated") === 'true',
                loading: false, 
                error: undefined,
            };

        case 'LOGIN_ERROR':
            return { ...state, loading: false, error: action.payload };
        case 'LOGIN_LOADING':
            return { ...state, loading: true };
        default:
            return state;
    }
  };


const useUserState = ({ children }) => {
    const [uState, uDispatch] = useReducer(reducer, initState);
    const Store = { uState, uDispatch };
  
    return (
        <UserContext.Provider value={Store}>
            {children}
        </UserContext.Provider>
    );
};
  
export default useUserState;