import {
    getBalletBookingSlots,
    createBalletBookingSlot,
    updateBalletBookingSlot,
    signUpUserToBalletBookingSlot,
    deleteBalletBookingSlot
} from "./BalletBooking";

export const getBalletBookingSlotsWithState = async (dispatch) => {
    dispatch({
        type: 'QUERY_LOADING'
    });

    const resData = await getBalletBookingSlots();

    if(resData.status !== 200) {
        dispatch({ type: 'QUERY_ERROR',  payload: resData.statusText });

    } else  {
        let resDataJSON = await resData.json();
        dispatch({ type: 'QUERY_COMPLETED', payload: resDataJSON});  
    }
}

export const createBalletBookingSlotWithState = async (dispatch, slot) => {
    dispatch({
        type: 'QUERY_LOADING'
    });

    const resData = await createBalletBookingSlot(slot);
    
    if(resData.status !== 200) {
        dispatch({ type: 'QUERY_ERROR', payload: resData.statusText });
    } else {
        dispatch({ type: 'SET_STATE_VALUES', payload: {cellsInEdit: null, reload: true}});
        dispatch({ type: 'QUERY_COMPLETED', payload: []});
    }
}

export const updateBalletBookingSlotWithState = async (dispatch, slot, newData, slotId ) => {
    dispatch({
        type: 'QUERY_LOADING'
    });

    let current = {...slot};
    let changes = {};

    if('MaxSlots' in newData) {
        current.MaxSlot = newData['MaxSlots'];
        changes.MaxSlot = newData['MaxSlots'];
    }
    if('EndTime' in newData) {
        current.EndTime = newData['EndTime'];
        changes.EndTime = newData['EndTime'];
    }

    let queryParams = {
        current: current,
        previous: slot,
        changes: changes
    };

    const resData = await updateBalletBookingSlot(queryParams, slotId);

    if(resData.status !== 200) {
        dispatch({ type: 'QUERY_ERROR', payload: resData.statusText });
    } else {
        dispatch({ type: 'SET_STATE_VALUES', payload: {cellsInEdit: null, reload: true}});
        dispatch({ type: 'QUERY_COMPLETED', payload: []});
    }
}

export const signUpUserToBalletBookingSlotWithState = async (dispatch, newData, slotId) => {
    dispatch({
        type: 'QUERY_LOADING'
    });
    
    let queryParams = {
        FullName: newData.FullName,
        Email: newData.Email,
        Add: newData.Add,  
        Telephone: newData.Telephone,
        ID: slotId
    };

    const resData = await signUpUserToBalletBookingSlot(queryParams);

    if(resData.status !== 200) {
        dispatch({ type: 'QUERY_ERROR', payload: resData.statusText });
    } else {
        dispatch({ type: 'SET_STATE_VALUES', payload: {cellsInEdit: null, reload: true}});
        dispatch({ type: 'QUERY_COMPLETED', payload: []});
    }
}

export const deleteUserToBalletBookingSlotWithState = async (dispatch, slot, userData, slotId ) => {
    dispatch({
        type: 'QUERY_LOADING'
    });

    let newSignees = slot.Signees.filter(signee => {
        return !(signee.Email === userData.Email && signee.FullName === userData.FullName && signee.Telephone === userData.Telephone);
    }); 

    let queryParams = {
        current: {...slot, Signees: newSignees},
        previous: slot,
        changes: {Signees: [userData]}
    };

    const resData = await updateBalletBookingSlot(queryParams, slotId);

    if(resData.status !== 200) {
        dispatch({ type: 'QUERY_ERROR', payload: resData.statusText });
    } else {
        dispatch({ type: 'SET_STATE_VALUES', payload: {cellsInEdit: null, reload: true}});
        dispatch({ type: 'QUERY_COMPLETED', payload: []});
    }
}

export const deleteBalletBookingSlotWithState = async (dispatch, slotID) => {
    dispatch({
        type: 'QUERY_LOADING'
    });

    const resData = await deleteBalletBookingSlot(slotID);

    if(resData.status !== 200) {
        dispatch({ type: 'QUERY_ERROR', payload: resData.statusText });
    } else {
        dispatch({ type: 'SET_STATE_VALUES', payload: {cellsInEdit: null, reload: true}});
        dispatch({ type: 'QUERY_COMPLETED', payload: []});
    }
}

export default {
    getBalletBookingSlotsWithState,
    createBalletBookingSlotWithState,
    updateBalletBookingSlotWithState,
    signUpUserToBalletBookingSlotWithState,
    deleteBalletBookingSlotWithState,
};